import useSWR from "swr";

import { fetcher } from "~/src/modules/api";

import { getPolygons } from "~/src/schema/filters";

/**
 *
 * @param query.query
 * @param query - The root object
 * @param query.shouldFetch - The root object
 * @param query.subtract - The root object
 * @param swrOptions
 * @param query.filtersetting
 * @example
 */
export default function useProjectsStats({
  filtersetting = "standard",
  query,
  shouldFetch = true
}, swrOptions) {
  const {
    direction,
    layout,
    marked,
    page,
    polygon,
    position,
    radius,
    sort,
    stats,
    ...restQuery
  } = query;

  const subtract = (filtersetting !== "standard");

  const newQuery = {
    ...restQuery,
    polygon: getPolygons(polygon),
    position,
    ...(position && {
      radius
    }),
    ...(subtract && {
      stats
    })
  };

  if (!newQuery.activeBBox) {
    delete newQuery.bBox;
    delete newQuery.mapZoom;
    delete newQuery.centerLat;
    delete newQuery.centerLng;
  }

  const parameters = new URLSearchParams(
    Object.fromEntries(
      Object.entries(newQuery).filter(([key, value]) => value !== undefined)
    )
  );

  const {
    data, error, mutate
  } = useSWR(() => (shouldFetch ? `/projects/stats?${parameters}` : null), fetcher, swrOptions);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    stats: data?.payload?.stats
  };
}
