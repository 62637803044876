import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { useEffect, useState } from "react";

import { priceLabels, unitCategoryLabels } from "~/src/modules/labels";

import useUnitCategories from "~/src/hooks/use-unit-categories";

/**
 *
 * @param props - The root object
 * @param props.activeCategory - The root object
 * @param props.categories - The root object
 * @param props.setActiveCategory - The root object
 * @param props.className - The root object
 * @param props.isManual - The root object
 * @param props.isUnits - The root object
 * @example
 */
export default function CategoryDropdown({
  activeCategory,
  categories,
  className,
  isUnits = true,
  setActiveCategory
}) {
  const { unit_categories: unitCategories } = useUnitCategories();

  const [categoryOptions, setCategoryOptions] = useState(categories);

  useEffect(() => {
    if (unitCategories && isUnits) {
      setCategoryOptions(
        [...categories]
          .sort((categoryCodeA, categoryCodeB) => {
            const sortingA = unitCategories ? Number(unitCategories.find(({ code }) => code === categoryCodeA).sorting) : 0;

            const sortingB = unitCategories ? Number(unitCategories.find(({ code }) => code === categoryCodeB).sorting) : 0;

            return sortingA - sortingB;
          })
      );
    }
    else if (categories.length > 0) {
      setCategoryOptions(categories);
    }
  }, [unitCategories, categories]);

  return (
    <Menu as="div" className={cn("relative inline-flex", className)}>
      <Menu.Button className="flex items-center justify-center gap-1 font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        <span>{(isUnits) ? unitCategoryLabels?.[activeCategory]?.singular : priceLabels?.[activeCategory]}</span>

        <ChevronDownIcon aria-hidden="true" className="size-5 text-gray-500" />
      </Menu.Button>

      <Menu.Items className="absolute right-0 top-8 z-10 min-w-52 origin-top-right rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5">
        {categoryOptions.map((categoryCode) => (
          <Menu.Item key={categoryCode}>
            {({ active }) => (
              <button
                id={categoryCode}
                onClick={() => setActiveCategory(categoryCode)}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "text-gray-700": !active
                })}
              >
                <span>{(isUnits) ? unitCategoryLabels?.[categoryCode]?.singular : priceLabels?.[categoryCode]}</span>
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>

  );
}
