import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.className - The root object
 * @example
 */
export default function DottedLineIcon({ className }) {
  return (
    <svg className={cn("!w-[1.8rem] !top-0", className)} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <line
        stroke="black"
        strokeDasharray={[20, 20]}
        strokeDashoffset={0}
        strokeWidth={4}
        x1="0"
        x2="100"
        y1="50"
        y2="50"
      />
    </svg>
  );
}
