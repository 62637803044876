import { camelCase } from "lodash-es";

import collator from "~/src/modules/collator";
import {
  colorPaletteWohnungenByYear
} from "~/src/modules/color-palletes";
import {
  countWohnungenByYearLabels as legendPickerWohnungenByYear, unitCategoryLabels
} from "~/src/modules/labels";

import HoverTooltip from "~/src/ui/charts/tooltip";

const colorPalette = [
  // "hsl(168, 100%, 21%)",
  // "hsl(146, 47%, 62%)",
  // "hsl(99, 52%, 68%)",
  // "hsl(187, 38%, 45%)",
  // "hsl(206, 61%, 35%)"

  "hsl(345, 49%, 34%)",
  "hsl(325, 39%, 33%)",
  "hsl(290, 25%, 32%)",
  "hsl(245, 22%, 34%)",
  "hsl(217, 30%, 30%)",
  "hsl(203, 30%, 26%)"

];

/*
  #9B5668 344°, 29%, 47%
  #A86B7B 344°, 26%, 54%
  #B4808E 344°, 26%, 60%
  #C196A1 345°, 26%, 67%
  #CDABB3 346°, 25%, 74%
*/

const tooltipStructure = {
  buyers: [
    "label",
    "amount",
    "totalPercentage"
  ],
  default: [
    "label",
    "amount",
    "percentage"
  ],
  rooms: [
    "label",
    "percentage",
    "averageSquareMeterPrice"
  ],
  scatter: [
    "top",
    "area",
    "price",
    "date"
  ]
};

const chartLabels = {
  unitsBuyersAge: new Proxy(
    {},
    {
      get: (target, name) => {
        if (name === "unknown") {
          return "unbekannt";
        }
        else if (["from", "to"].some((rangeString) => name.includes(rangeString))) {
          const firstSplit = name.split("from");

          if (firstSplit.length === 1) {
            const [empty, to] = firstSplit[0].split("to");

            return `< ${to} Jahre`;
          }

          const secondSplit = firstSplit[1].split("to");

          if (secondSplit.length === 1) {
            const [from] = secondSplit;

            return `${Number(from) - 1}+ Jahre`;
          }

          const [from, to] = secondSplit;

          return `${from} - ${to} Jahre`;
        }

        // should never happen
        return "";
      }
    }
  ),
  unitsBuyersGender: {
    female: "weiblich",
    male: "männlich",
    unassignable: "nicht zuordenbar"
  },
  unitsBuyersGraduated: {
    no: "Nicht Akademiker",
    yes: "Akademiker"
  },
  unitsBuyersPartnership: {
    no: "Keine Eigentümerpartnerschaft",
    unknown: "unbekannt",
    yes: "Eigentümerpartnerschaft"
  },
  unitsBuyersProvenance: {
    foreign: "Ausland",
    national: "Österreich",
    unknown: "unbekannt"
  },
  unitsBuyersType: {
    company: "Unternehmen",
    private: "Privatperson",
    unknown: "unbekannt"
  },
  unitsMainCategory: Object.fromEntries(Object.entries(unitCategoryLabels).map(([key, { singular }]) => [key, singular])),
  unitsOfferRooms: {
    unknown: "unbekannt",
    ...Object.fromEntries(Array.from({ length: 5 }).fill().map((empty, index) => [`${index + 1}`, `${index + 1} Zimmer`])),
    "6+": "6+ Zimmer"
  },
  unitsSaleRooms: {
    unknown: "unbekannt",
    ...Object.fromEntries(Array.from({ length: 5 }).fill().map((empty, index) => [`${index + 1}`, `${index + 1} Zimmer`])),
    "6+": "6+ Zimmer"
  }
};

const unitsBuyersAgeSorting = [
  "to",
  "fromto",
  "from",
  "unknown"
];

const unitsBuyersGenderSorting = [
  "male",
  "female",
  "unassignable"
];

const unitsOfferRoomsSorting = [
  ...Array.from({ length: 5 }).fill().map((empty, index) => `${index + 1}`),
  "6+",
  "unknown"
];

const unitsSaleRoomsSorting = [
  ...Array.from({ length: 5 }).fill().map((empty, index) => `${index + 1}`),
  "6+",
  "unknown"
];

/**
 *
 * @param statistics
 * @param statistics.meta
 * @param statistics.meta.types
 * @param statistics.data
 * @param options
 * @param options.fancyLabels
 * @param statistics.tooltipType
 * @param tooltipType
 * @param statistics.meta.types.fancyLabels
 * @param tooltipType.fancyLabels
 * @param tooltipType.useTotalPercentage
 * @param tooltipType.averageSquareMeterPriceKey
 */

/**
 *
 * @param root0
 * @param root0.barData
 */

const barDataToColor = (barData) => barData.map((singleDataEntry) => {
  const colorForBar = Object.keys(singleDataEntry)
    .filter((entryKey) => entryKey !== "year" && !entryKey.endsWith("_project"))
    .map((entryKey) => colorPaletteWohnungenByYear[entryKey]);

  return {
    ...singleDataEntry,
    ...Object.assign({}, ...colorForBar)
  };
});

const barDataToLegend = (barData) => barData.reduce((legendPairs, singleDataEntry) => {
  const legendData = Object.entries(singleDataEntry)
    .filter(([entryKey, value]) => entryKey !== "year" && !entryKey.endsWith("_project") && value > 0)
    .map(([entryKey, value]) => ({
      color: colorPaletteWohnungenByYear[entryKey][`${entryKey}Color`],
      label: legendPickerWohnungenByYear[entryKey]
    }))
    .toSorted(({ label: labelA }, { label: labelB }) => collator.compare(labelA, labelB));

  for (const legendDataPoint of legendData) {
    if (!legendPairs.some((legendPair) => legendPair.label === legendDataPoint.label)) {
      legendPairs.push(legendDataPoint);
    }
  }

  return legendPairs;
}, []);

/**
 *
 * @param root0 - The root object
 * @param root0.barData - The root object
 * @example
 */
const transformToBarData = ({
  barData
}) => {
  const chartData = [...barDataToColor(barData)];
  const legendData = barDataToLegend(barData);

  return {
    chartData,
    legendData
  };
};

/**
 *
 * @param root0 - The root object
 * @param root0.meta - The root object
 * @param root0.meta.types - The root object
 * @param root0.data - The root object
 * @param tooltipType
 * @param root1 - The root object
 * @param root1.fancyLabels - The root object
 * @param root1.useTotalPercentage - The root object
 * @param root1.averageSquareMeterPriceKey - The root object
 * @example
 */
const transformToPieData = (
  {
    data,
    meta: { types }
  },
  tooltipType = "default",
  {
    averageSquareMeterPriceKey,
    fancyLabels = true,
    useTotalPercentage = false
  } = {
    fancyLabels: true,
    useTotalPercentage: false
  }
) => {
  let colorOffset = 0;
  const [mainType, subType] = types;
  const camelCaseMainType = camelCase(mainType);

  const sortedDataEntries = [...Object.entries(data)]
    .sort(([categoryKeyA], [categoryKeyB]) => {
      switch (camelCaseMainType) {
        case "unitsBuyersAge": {
          const [sortingIndexA, sortingIndexB] = [categoryKeyA, categoryKeyB]
            .map((categoryKey) => unitsBuyersAgeSorting.indexOf(categoryKey.replaceAll(/\d+/gu, "")));

          return sortingIndexA - sortingIndexB;
        }

        case "unitsBuyersGender": {
          const [sortingIndexA, sortingIndexB] = [categoryKeyA, categoryKeyB]
            .map((categoryKey) => unitsBuyersGenderSorting.indexOf(categoryKey));

          return sortingIndexA - sortingIndexB;
        }

        case "unitsOfferRooms": {
          const [sortingIndexA, sortingIndexB] = [categoryKeyA, categoryKeyB]
            .map((categoryKey) => unitsOfferRoomsSorting.indexOf(categoryKey));

          return sortingIndexA - sortingIndexB;
        }

        case "unitsSaleRooms": {
          const [sortingIndexA, sortingIndexB] = [categoryKeyA, categoryKeyB]
            .map((categoryKey) => unitsSaleRoomsSorting.indexOf(categoryKey));

          return sortingIndexA - sortingIndexB;
        }

        default:
          return 0;
      }
    });

  return sortedDataEntries
    .filter(([categoryKey, { absolute }]) => absolute)
    .map(([categoryKey, category], index) => {
      const slice = {
        id: categoryKey,
        color: colorPalette[(index + colorOffset) % colorPalette.length],
        label: chartLabels[camelCaseMainType][categoryKey],
        percentage: category.percentage,
        totalPercentage: category.percentage || category.totalPercentage,
        value: category.absolute
      };

      slice.tooltip = <HoverTooltip data={slice} key={categoryKey} tooltipStructure={tooltipStructure[tooltipType]} />;

      if (category.sub && Object.keys(category.sub).length > 0) {
        const camelCaseSubType = camelCase(subType);

        slice.sub = Object.entries(category.sub)
          .filter(([subCategoryKey, { absolute }]) => absolute)
          .map(([subCategoryKey, subCategory], index2) => {
            const subSliceLabel = fancyLabels
              ? [slice.label, chartLabels[camelCaseSubType][subCategoryKey]].join(" | ")
              : chartLabels[camelCaseSubType][subCategoryKey];

            const subSliceLabelUse = ([
              "female",
              "male",
              "unknown"
            ].includes(categoryKey))
              ? chartLabels[camelCaseMainType][categoryKey]
              : subSliceLabel;

            const sliceChild = {
              id: [slice.id, subCategoryKey].join("_"),
              color: colorPalette[(index + index2 + colorOffset) % colorPalette.length],
              label: subSliceLabelUse,
              percentage: subCategory.percentage,
              totalPercentage: useTotalPercentage ? subCategory.totalPercentage : subCategory.percentage,
              value: subCategory.absolute
            };

            if (averageSquareMeterPriceKey) {
              sliceChild.averageSquareMeterPrice = subCategory[averageSquareMeterPriceKey];
            }

            sliceChild.tooltip = <HoverTooltip data={sliceChild} key={subCategoryKey} tooltipStructure={tooltipStructure[tooltipType]} />;

            colorOffset += 1;

            return sliceChild;
          });
      }

      return slice;
    });
};

const areaScaler = (datapointArea, min, max) => Math.round(8 + (16 * (datapointArea / max)));

const dataLabels = {
  dataWithZKey: "Mit Flächenangabe",
  dataWithoutZKey: "Ohne Flächenangabe"
};

/**
 *
 * @param data
 * @param options - The root object
 * @param options.xKey - The root object
 * @param options.yKey - The root object
 * @param options.zKey - The root object
 * @param options.color - The root object
 * @example
 */
const transformToScatterData = (data, {
  color = "red",
  xKey,
  yKey,
  zKey
}) => {
  const filteredData = data
    .filter(({
      [xKey]: xValue,
      [yKey]: yValue
    }) => {
      const neededValues = [xValue, yValue];

      return neededValues
        .every((value, index) => {
          if (index === 0) {
            return value !== null;
          }
          const valueNumber = Number(value);

          return !Number.isNaN(valueNumber) && valueNumber !== 0;
        });
    });

  const {
    xMax,
    xMin,
    yMax,
    yMin,
    zMax,
    zMin
  } = filteredData
    .reduce(
      (
        accumulator,
        {
          [xKey]: xValue,
          [yKey]: yValue,
          [zKey]: zValue = 1
        }
      ) => ({
        xMax: Math.max(accumulator.xMax, new Date(xValue)),
        xMin: Math.min(accumulator.xMin, new Date(xValue)),
        yMax: Math.max(accumulator.yMax, yValue),
        yMin: Math.min(accumulator.yMin, yValue),
        zMax: Math.max(accumulator.zMax, zValue),
        zMin: Math.min(accumulator.zMin, zValue)
      }),
      {
        xMax: -Infinity,
        xMin: Infinity,
        yMax: -Infinity,
        yMin: Infinity,
        zMax: -Infinity,
        zMin: Infinity
      }
    );

  const dataWithZKey = filteredData.filter(({ [zKey]: zValue }) => zValue !== null);
  const dataWithoutZKey = filteredData.filter(({ [zKey]: zValue }) => zValue === null);

  return Object.entries({
    dataWithZKey,
    dataWithoutZKey
  })
    .map(([id, currentData]) => ({
      id,
      color: color === "red" ? "#822c42bf" : "#275968bf",
      data: currentData.map((dataPoint) => ({
        id: dataPoint.id,
        size: dataPoint[zKey] ? areaScaler(dataPoint[zKey], zMin, zMax) : 8,
        tooltip: (
          <HoverTooltip
            data={dataPoint}
            tooltipStructure={tooltipStructure.scatter}
            xyz={{
              xKey,
              yKey,
              zKey
            }}
          />
        ),
        x: new Date(dataPoint[xKey]),
        y: Number(dataPoint[yKey])
      })),
      label: dataLabels[id],
      xMax,
      xMin,
      yMax,
      yMin
    }));
};

export {
  transformToBarData, transformToPieData, transformToScatterData
};
