/* eslint-disable no-empty-function */

import {
  BarsArrowDownIcon,
  BarsArrowUpIcon
} from "@heroicons/react/24/solid";

/**
 * @typedef {object} SortDirectionButtonProps
 * @property {"asc"|"desc"} [direction] - The sort direction.
 * @property {boolean} isHandleItemClickDisabled - Whether the button is disabled.
 * @property {Function} [onSortDirectionChange] - The sort direction change event handler.
 */

const defaultOnSortDirectionChange = () => {};

/**
 * The select all projects filter component.
 *
 * @param {SortDirectionButtonProps} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 * @example
 */
const SortDirectionButton = ({
  direction = "asc",
  isHandleItemClickDisabled,
  onSortDirectionChange = defaultOnSortDirectionChange
}) => (
  <button
    className="flex h-6 w-8 cursor-pointer items-center justify-center rounded-sm border border-gray-200 bg-white p-2 text-gray-500 hover:bg-gray-50 lg:h-[1.875rem] lg:w-9 lg:p-[0.3rem]"
    disabled={isHandleItemClickDisabled}
    type="button"
    onClick={() => {
      onSortDirectionChange(direction === "asc" ? "desc" : "asc");
    }}
  >
    {direction === "asc" &&
      <BarsArrowUpIcon className="w-full lg:h-full" />}

    {direction === "desc" &&
      <BarsArrowDownIcon className="w-full lg:h-full" />}
  </button>
);

export default SortDirectionButton;
