import { diff } from "deep-object-diff";

import { hasActiveOwnerFields } from "~/src/modules/share-deal-helper";

import ShareDealOwnerCardItem from "~/src/features/share-deal-owner-card-item";

/**
 *
 * @param root0 - The root object
 * @param root0.owner - The root object
 * @example
 */
export default function ShareDealOwnerCard({ owner }) {
  const { active: hasCurrentOwner, ownerObj: currentOwnerObject } = hasActiveOwnerFields(owner, "current");
  const { active: hasLegacyOwner, ownerObj: legacyOwnerObject } = hasActiveOwnerFields(owner, "legacy");

  const ownerDiff = diff(legacyOwnerObject, currentOwnerObject);

  return (
    <div className="space-y-4 divide-y overflow-hidden bg-gray-100 px-3 pb-3 text-sm text-gray-800 shadow sm:rounded-md">

      {hasCurrentOwner &&
        <ShareDealOwnerCardItem diff={ownerDiff} hasCurrentOwner={hasCurrentOwner} hasLegacyOwner={hasLegacyOwner} owner={owner} type="current" />}

      {hasLegacyOwner &&
        <ShareDealOwnerCardItem diff={ownerDiff} hasCurrentOwner={hasCurrentOwner} hasLegacyOwner={hasLegacyOwner} owner={owner} type="legacy" />}

    </div>
  );
}
