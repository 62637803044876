import cn from "classnames";

/**
 *
 * @param root0 - The root object
 * @param root0.title - The root object
 * @param root0.onClick - The root object
 * @param root0.customWidth - The root object
 * @param root0.fontSize - The root object
 * @example
 */
export default function FilterButton({
  customWidth = null, fontSize = "text-sm", onClick, title
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={cn("py-0.5 text-center bg-secondary-dark hover:bg-secondary-darker border border-secondary-darker rounded focus:outline-none text-gray-600", {
        [customWidth]: customWidth,
        [fontSize]: fontSize,
        "w-32": !customWidth
      })}
    >
      <span className="font-medium">{title}</span>
    </button>
  );
}

