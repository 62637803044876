import { formatDate } from "~/src/modules/formatters";

import RegionFilterTag from "~/src/features/filters/share-deal-region-filter-tag";

import SingleFilterTag from "~/src/ui/search-filter-tags/single-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.onSubmit - The root object
 * @param root0.handleClearFilters - The root object
 * @example
 */
export default function InsolvencyFilterTags({
  handleClearFilters, onSubmit, query
}) {
  const activeFilters = [];

  for (const [key, value] of Object.entries(query)) {
    if (![
      "automated",
      "direction",
      "page",
      "selection",
      "sortBy",
      "sortByTransaction",
      "type"
    ].includes(key) && value !== undefined) {
      if (query.type === "insolvenz") {
        if ([
          "cat",
          "company",
          "dateFrom",
          "dateTo"
        ].includes(key) && value !== undefined) {
          activeFilters.push(key);
        }
      }
      else {
        if (key === "regions" && value.length === 0) {
          continue;
        }
        if ([
          "cat",
          "company",
          "dateFrom",
          "dateTo"
        ].includes(key) && value !== undefined) {
          activeFilters.push(key);
        }
      }
    }
  }

  return (
    <div className="flex min-h-10 flex-wrap items-center justify-start gap-4">
      <SingleFilterTag
        label={query.cat}
        show={query.catId && query.cat}
        onClick={() => onSubmit({
          cat: undefined,
          catId: undefined
        })}
      />

      <SingleFilterTag label={`Eigentümer: ${query.owner}`} onClick={() => onSubmit({ owner: undefined })} show={query.type !== "insolvenz" && query.owner} />

      <SingleFilterTag label={`Schuldner: ${query.company}`} onClick={() => onSubmit({ company: undefined })} show={query.company} />

      <SingleFilterTag
        label={`Datum von: ${formatDate({ date: query.dateFrom })}`}
        show={query.dateFrom}
        onClick={() => onSubmit({
          dateFrom: undefined,
          direction: query.dateTo ? "desc" : undefined
        })}
      />

      <SingleFilterTag
        label={`Datum bis: ${formatDate({ date: query.dateTo })}`}
        show={query.dateTo}
        onClick={() => onSubmit({
          dateTo: undefined,
          direction: query.dateFrom ? "asc" : undefined
        })}
      />

      {query.type !== "insolvenz" &&
        <RegionFilterTag queryRegions={query.regions} submit={onSubmit} />}

      {(activeFilters.length > 0 || (query.type !== "insolvenz" && query.regions.length > 0)) && (
        <button className="ml-2 mt-0.5 text-sm font-semibold tracking-tight text-primary hover:underline focus:outline-none" onClick={handleClearFilters} type="button">
          Filter zurücksetzen
        </button>
      )}
    </div>
  );
}
