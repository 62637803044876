/* eslint-disable max-lines-per-function */
import {
  Dialog, DialogBackdrop, DialogPanel, DialogTitle, Label, Radio, RadioGroup
} from "@headlessui/react";
import cn from "classnames";
import {
  Fragment, useEffect
  , useState
} from "react";

import API from "~/src/modules/api";

import useStore from "~/src/hooks/use-store";
import useUserSettings from "~/src/hooks/use-user-settings";

/**
 *
 * @param root0 - The root object
 * @param root0.isOpen - The root object
 * @example
 */
export default function FilterBehaviorModal({ isOpen = false }) {
  const { mutate, userSettings } = useUserSettings();
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setSelectedOption(userSettings.filtersetting);
      mutate();
    }
  }, [isOpen]);

  useEffect(() => {
    if (userSettings) {
      setSelectedOption(userSettings.filtersetting);
    }
  }, [userSettings]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const [isRequestSubmitting, setIsRequestSubmitting] = useState(false);

  const toggleFilterBehaviorModal = useStore((state) => state.toggleFilterBehaviorModal);

  const timeOutSetting = 800;

  const handleClose = function handleClose() {
    toggleFilterBehaviorModal();
    setTimeout(() => {
      setIsRequestSubmitting(false);
    }, timeOutSetting);
  };

  const onSubmit = function onSubmit(event) {
    event.preventDefault();
    setIsRequestSubmitting(true);

    API.put("/user-settings/filter-update", {
      // eslint-disable-next-line camelcase
      user_settings: {
        filtersetting: selectedOption
      }
    })
      .finally(() => {
        handleClose();
        mutate();
        setSelectionMutate(true);
      });
  };

  return (
    <Dialog
      className="relative z-[150]"
      onClose={handleClose}
      open={isOpen}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex max-h-full w-full flex-col bg-white p-6 sm:max-h-full sm:max-w-xl sm:rounded-lg"
        >
          <form onSubmit={onSubmit}>
            <DialogTitle as="h3" className="flex w-full  justify-center text-lg font-medium leading-6 text-gray-900">
              Einstellung der Statistik-Buttons
            </DialogTitle>

            <RadioGroup onChange={handleOptionChange} value={selectedOption}>
              <Label className="sr-only">Option Filter</Label>

              <div className="mt-5 flex flex-col gap-y-5 sm:mt-6">
                <div
                  className="flex cursor-pointer flex-col gap-y-2"
                  onClick={() => handleOptionChange("standard")}
                >
                  <Radio value="standard">
                    {({ checked }) => (
                      <div className="flex items-center gap-2">
                        <span
                          aria-hidden="true"
                          className={cn("h-5 w-5 flex items-center justify-center text-center rounded-full border", {
                            "bg-primary border-transparent": checked,
                            "bg-white border-gray-300": !checked
                          })}
                        >
                          <span className="size-2 rounded-full bg-white" />
                        </span>

                        <div className="font-medium" >
                          Standardauswahl
                        </div>
                      </div>
                    )}
                  </Radio>

                  <div className="ml-[21px] rounded-lg bg-gray-50 px-2 py-[5px] text-left text-sm text-gray-800">
                    <>
                      Die Standardauswahl ermöglicht es Ihnen, unterschiedliche Statistik-Buttons gleichzeitig zu berücksichtigen.
                      Sie können sich beispielsweise sowohl Projekte mit einem berechneten Grundkostenanteil
                      {" "}

                      <span className="font-bold">als auch</span>

                      {" "}
                      Projekte mit Verkaufspreisen anzeigen lassen.
                    </>
                  </div>
                </div>

                <div
                  className="flex cursor-pointer flex-col gap-y-2"
                  onClick={() => handleOptionChange("detail")}
                >
                  <Radio value="detail">
                    {({ checked }) => (
                      <div className="flex items-center gap-2" >
                        <span
                          aria-hidden="true"
                          className={cn("h-5 w-5 flex items-center justify-center text-center rounded-full border", {
                            "bg-primary border-transparent": checked,
                            "bg-white border-gray-300": !checked
                          })}
                        >
                          <span className="size-2 rounded-full bg-white" />
                        </span>

                        <div
                          className="font-medium "
                        >
                          Detailauswahl
                        </div>
                      </div>
                    )}
                  </Radio>

                  <div className="ml-[21px] rounded-lg bg-gray-50 px-2 py-[5px] text-left text-sm text-gray-800">
                    <>
                      Mit der Detailauswahl können Sie Projekte rausfiltern, die mehrere Kriterien der Statistik-Buttons erfüllen müssen.
                      Sie können beispielsweise Projekte herausfiltern, die über einen berechneten Grundkostenanteil
                      {" "}

                      <span className="font-bold">sowie</span>

                      {" "}
                      Objekte mit Verkaufspreis Eigennutzung verfügen.
                    </>
                  </div>
                </div>

              </div>
            </RadioGroup>

            <div className="mt-5 flex w-full justify-between sm:mt-6">
              <button
                className="py-2 text-sm font-medium text-gray-500 underline hover:text-gray-800"
                onClick={handleClose}
                type="button"
              >
                Schließen
              </button>

              {isRequestSubmitting && (
                <button
                  disabled
                  className="ml-3 inline-flex w-44 cursor-not-allowed justify-center rounded-md border border-transparent bg-primary py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-lighter focus:ring-offset-2"
                  type="submit"
                >
                  <svg className="-ml-1 mr-3 size-5 animate-spin text-white" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />

                    <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
                  </svg>

                  <span>wird gesendet...</span>
                </button>
              )}

              {!isRequestSubmitting && (
                <button
                  className="ml-3 inline-flex w-44 justify-center rounded-md border border-transparent bg-primary py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-lighter focus:ring-offset-2"
                  type="submit"
                >
                  Änderungen speichern
                </button>
              )}
            </div>

          </form>

        </DialogPanel>
      </div>
    </Dialog>
  );
}
