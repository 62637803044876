import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Redirect } from "react-router-dom";

import DeleteWatchlistModal from "~/src/features/delete-watchlist-modal";
import EditWatchlistModal from "~/src/features/edit-watchlist-modal";
import NewWatchlistModal from "~/src/features/new-watchlist-modal";
import WatchlistItem from "~/src/features/watchlist-item";

import useWatchlists from "~/src/hooks/use-watchlists";

import Page from "~/src/ui/page";

/**
 *
 * @example
 */
export default function WatchlistsPage() {
  const {
    isError, mutate, watchlists
  } = useWatchlists();
  const [showWatchlistNewModal, setWatchlistNewModal] = useState(false);
  const [showWatchlistEditModal, setWatchlistEditModal] = useState(false);
  const [showWatchlistDeleteModal, setWatchlistDeleteModal] = useState(false);
  const [editableWatchlist, setEditableWatchlist] = useState(null);
  const [deletableWatchlist, setDeletableWatchlist] = useState(null);
  const [nameFilter, setNameFilter] = useState("");

  if (isError) {
    <Redirect to="/error" />;
  }

  /**
   *
   * @param watchlist
   * @example
   */
  function handleEdit(watchlist) {
    setEditableWatchlist(watchlist);
    setWatchlistEditModal(true);
  }

  /**
   *
   * @param watchlist
   * @example
   */
  function handleDelete(watchlist) {
    setDeletableWatchlist(watchlist);
    setWatchlistDeleteModal(true);
  }

  return (
    <Page title="Merklisten">

      <NewWatchlistModal hideModal={() => setWatchlistNewModal(false)} mutateWatchlists={mutate} show={showWatchlistNewModal} />

      <EditWatchlistModal hideModal={() => setWatchlistEditModal(false)} mutateWatchlists={mutate} show={showWatchlistEditModal} watchlist={editableWatchlist} />

      <DeleteWatchlistModal hideModal={() => setWatchlistDeleteModal(false)} mutateWatchlists={mutate} show={showWatchlistDeleteModal} watchlist={deletableWatchlist} />

      <div className="mx-auto mt-8 flex items-center justify-between px-4 xl:container lg:px-16">
        <div>
          <h2 className="text-3xl font-medium text-gray-700">Merklisten</h2>

          <p className="mt-3 text-lg text-gray-500">
            Organisieren Sie Projekte in eigenen Merklisten.
          </p>
        </div>

        <div>
          {watchlists?.length < 20 && (
            <>
              <button className="flex items-center text-gray-700 underline hover:no-underline focus:outline-none" onClick={() => setWatchlistNewModal(true)} type="button">
                Merkliste hinzufügen
              </button>

              <p className="mt-1 text-right text-sm  text-gray-500">{watchlists.length} von 20 Merklisten</p>
            </>
          )}

          {watchlists?.length >= 20 && (
            <>
              <p className="text-right text-lg font-light text-gray-700">20 von 20 Merklisten</p>

              <p className="mt-1 text-right text-sm  text-gray-500">maximale Anzahl erreicht</p>
            </>
          )}
        </div>
      </div>

      <div className="mx-auto px-4 py-5 xl:container lg:px-16">

        <div className="mt-4 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div className="relative col-span-1">
            <input
              autoComplete="off"
              className="block h-12 w-full rounded-sm border-gray-200 pl-10 placeholder:text-gray-400 hover:border-gray-800 focus:border-gray-800 focus:ring-0"
              id="watchlist-filter"
              name="watchlist-filter"
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Merklisten filtern..."
              type="text"
              value={nameFilter}
            />

            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
              <MagnifyingGlassIcon className="size-6 text-gray-400 " />
            </div>
          </div>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {watchlists?.map((watchlist) => <WatchlistItem handleDelete={handleDelete} handleEdit={handleEdit} key={`watchlist_${watchlist.uuid}`} nameFilter={nameFilter} watchlist={watchlist} />)}
        </div>

      </div>

    </Page>
  );
}
