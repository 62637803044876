import dateFormatter from "~/src/modules/date-formatter";
import { generateGkaNotice } from "~/src/modules/helpers";
import {
  constructionPhaseLabels, marketingPhaseLabels, priceLabels
} from "~/src/modules/labels";

/**
 *
 * @param workbook
 * @param project
 * @example
 */
export default function projectSheetGenerator(workbook, project) {
  const projectSheet = workbook.addWorksheet("Projektinformationen");

  projectSheet.getColumn(1).width = 38;
  projectSheet.getColumn(2).width = 12;
  projectSheet.getColumn(3).width = 12;
  projectSheet.getColumn(4).width = 12;
  projectSheet.getColumn(5).width = 12;

  const row1 = projectSheet.addRow();

  row1.getCell(1).value = project.name;
  row1.getCell(1).font = { size: 18 };

  for (const location of project.locations) {
    const row = projectSheet.addRow();

    row.getCell(1).value = `${location.zipcodeZipcode} ${location.cityName}, ${location.streetName} ${location.housenumber}`;
    row.getCell(1).font = { size: 12 };
  }

  projectSheet.addRow();

  const baustatus = projectSheet.addRow();

  baustatus.getCell(1).value = "Baustatus:";
  baustatus.getCell(1).font = { size: 12 };

  const constructionFinishDate = calculateFinishedDate({
    construction_infos: project?.construction_infos,
    timeline: project?.timeline
  });

  baustatus.getCell(2).value = `${constructionPhaseLabels[project?.timeline.current_construction_phase || "unknown"]}${constructionFinishDate ? `, ${constructionFinishDate}` : ""}`;
  baustatus.getCell(2).font = { size: 12 };

  const vermarktung = projectSheet.addRow();

  vermarktung.getCell(1).value = "Vermarktungsstatus:";
  vermarktung.getCell(1).font = { size: 12 };
  vermarktung.getCell(2).value = marketingPhaseLabels[project?.timeline?.current_marketing_phase || "unknown"];
  vermarktung.getCell(2).font = { size: 12 };

  projectSheet.addRow();

  if (project.buyable) {
    const eigennutzer = projectSheet.addRow();

    eigennutzer.getCell(1).value = `ø ${priceLabels.averageOfferPriceNormal}:`;
    eigennutzer.getCell(1).font = { size: 12 };
    eigennutzer.getCell(2).value = project.avg_offer_price_eigennutzer && Number.parseFloat(project.avg_offer_price_eigennutzer);
    eigennutzer.getCell(2).numFmt = "\"€ \"#,##0.00";
    eigennutzer.getCell(2).font = {
      bold: true,
      size: 12
    };

    const investor = projectSheet.addRow();

    investor.getCell(1).value = `ø ${priceLabels.averageOfferPriceInvestorNet}:`;
    investor.getCell(1).font = { size: 12 };
    investor.getCell(2).value = project.avg_offer_price_investor && Number.parseFloat(project.avg_offer_price_investor);
    investor.getCell(2).numFmt = "\"€ \"#,##0.00";
    investor.getCell(2).font = {
      bold: true,
      size: 12
    };
  }

  if (project.rentable) {
    const miete_netto = projectSheet.addRow();

    miete_netto.getCell(1).value = `ø ${priceLabels.averageRentNet}:`;
    miete_netto.getCell(1).font = { size: 12 };
    miete_netto.getCell(2).value = project.avg_rent_netto && Number.parseFloat(project.avg_rent_netto);
    miete_netto.getCell(2).numFmt = "\"€ \"#,##0.00";
    miete_netto.getCell(2).font = {
      bold: true,
      size: 12
    };

    const miete_gesamt = projectSheet.addRow();

    miete_gesamt.getCell(1).value = `ø ${priceLabels.averageRentTotal}:`;
    miete_gesamt.getCell(1).font = { size: 12 };
    miete_gesamt.getCell(2).value = project.avg_rent_bk && Number.parseFloat(project.avg_rent_bk);
    miete_gesamt.getCell(2).numFmt = "\"€ \"#,##0.00";
    miete_gesamt.getCell(2).font = {
      bold: true,
      size: 12
    };
  }
  projectSheet.addRow();

  if (project.buyable) {
    const price_netto = projectSheet.addRow();

    price_netto.getCell(1).value = `ø ${priceLabels.averageSalePriceNormal}:`;
    price_netto.getCell(1).font = { size: 12 };
    price_netto.getCell(2).value = project.avg_sale_price_eigennutzer && Number.parseFloat(project.avg_sale_price_eigennutzer);
    price_netto.getCell(2).numFmt = "\"€ \"#,##0.00";
    price_netto.getCell(2).font = {
      bold: true,
      size: 12
    };

    const price_brutto = projectSheet.addRow();

    price_brutto.getCell(1).value = `ø ${priceLabels.averageSalePriceInvestorNet}:`;
    price_brutto.getCell(1).font = { size: 12 };
    price_brutto.getCell(2).value = project.avg_sale_price_investor && Number.parseFloat(project.avg_sale_price_investor);
    price_brutto.getCell(2).numFmt = "\"€ \"#,##0.00";
    price_brutto.getCell(2).font = {
      bold: true,
      size: 12
    };
  }

  projectSheet.addRow();

  const gkaNotice = generateGkaNotice(project);

  const gka = projectSheet.addRow();

  gka.getCell(1).value = "Grundkostenanteil:";
  gka.getCell(1).font = { size: 12 };
  gka.getCell(2).value = (project.gka && project.gka_from && Number.parseFloat(project.gka) !== 0) ? Number.parseFloat(project.gka) : "";
  gka.getCell(2).numFmt = "\"€ \"#,##0.00";
  gka.getCell(2).font = {
    bold: true,
    size: 12
  };
  gka.getCell(3).value = gkaNotice?.[0] ?? "";
  gka.getCell(3).font = { size: 12 };

  const gkaInfo = projectSheet.addRow();

  gkaInfo.getCell(3).value = gkaNotice?.[1] ?? "";
  gkaInfo.getCell(3).font = { size: 12 };

  projectSheet.addRow();

  const categories = projectSheet.addRow();

  categories.getCell(1).value = "Objektkategorien";
  categories.getCell(1).font = {
    bold: true,
    size: 12
  };
  categories.getCell(2).value = "Einheiten";
  categories.getCell(2).font = { size: 12 };
  categories.getCell(3).value = "Fläche";
  categories.getCell(3).font = { size: 12 };
  categories.getCell(4).value = "Fläche (bis)";
  categories.getCell(4).font = { size: 12 };

  for (const quantity of project.quantities.sort((a, b) => a.category.id - b.category.id)) {
    const row = projectSheet.addRow();

    row.getCell(1).value = quantity.category.name;
    row.getCell(2).value = quantity.total_units;
    row.getCell(3).value = quantity.total_area_range_from && Number.parseFloat(quantity.total_area_range_from);
    row.getCell(3).numFmt = "#,##0.00\" m²\"";
    row.getCell(4).value = quantity.total_area_range_to && Number.parseFloat(quantity.total_area_range_to);
    row.getCell(4).numFmt = "#,##0.00\" m²\"";
  }
}

/**
 *
 * @param options0 - The root object
 * @param options0.construction_infos - The root object
 * @param options0.timeline - The root object
 * @example
 */
function calculateFinishedDate({ construction_infos, timeline }) {
  const completed = timeline?.current_construction_phase === "construction_phase_completed";
  const completed_date = timeline?.construction_phase_completed_date;

  if (completed && completed_date) {
    return dateFormatter(completed_date, timeline?.construction_phase_completed_date_format);
  }

  if (!completed && construction_infos?.estimated_finish_date) {
    return `geplante Fertigstellung: ${dateFormatter(construction_infos?.estimated_finish_date, construction_infos?.estimated_finish_date_format)}`;
  }

  return null;
}
