import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { shareDealPropertySortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0 - The root object
 * @param root0.sortBy - The root object
 * @param root0.onSubmit - The root object
 * @example
 */
export default function SortShareDealPropertiesDropdown({ onSubmit, sortBy = "date" }) {
  return (
    <Menu as="div" className="relative ml-8">
      <Menu.Button className="inline-flex h-10 w-full items-center justify-center border-b border-secondary-light text-base font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        {shareDealPropertySortLabels[sortBy]}

        <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-0.5 size-5 text-gray-500" />
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 -mt-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="w-full py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "date" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "date",
                  "text-gray-700": !active
                })}
              >
                <span>Datum</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "kg" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "kg",
                  "text-gray-700": !active
                })}
              >
                KG
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "ez" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "ez",
                  "text-gray-700": !active
                })}
              >
                EZ
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "zipcode" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "zipcode",
                  "text-gray-700": !active
                })}
              >
                PLZ (Liegenschaft)
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "street" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "street",
                  "text-gray-700": !active
                })}
              >
                Straße (Liegenschaft)
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "company" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "company",
                  "text-gray-700": !active
                })}
              >
                Eigentümer
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "fbnr" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "fbnr",
                  "text-gray-700": !active
                })}
              >
                Fb-Nr. Eigentümer
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "area" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "area",
                  "text-gray-700": !active
                })}
              >
                Fläche EZ
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
