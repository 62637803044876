import { PlusIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

/**
 *
 * @example
 */
export default function EmptyProjectBucket() {
  return (
    <div className="mt-20 text-center">
      <h3 className="mt-2 text-sm font-medium text-gray-900">Diese Merkliste ist noch leer.</h3>

      <p className="mt-1 text-sm text-gray-500">Fügen Sie neue Projekte in der Projektliste hinzu.</p>

      <div className="mt-6">
        <Link
          className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          to="/liste"
        >
          <PlusIcon aria-hidden="true" className="-ml-1 mr-2 size-5" />
          Zu den Projekten
        </Link>
      </div>
    </div>
  );
}
