import { fundingFilterLabels } from "~/src/modules/labels";
import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
export default function FundingSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const hasFreifinanziert = items.includes("freifinanziert");
  const hasGefoerdert = items.includes("gefoerdert");
  const hasGemeinde = items.includes("gemeinde");

  const hasTemporaryFreifinanziert = temporaryItems.includes("freifinanziert");
  const hasTemporaryGefoerdert = temporaryItems.includes("gefoerdert");
  const hasTemporaryGemeinde = temporaryItems.includes("gemeinde");

  /**
   *
   * @param event
   * @param key
   * @example
   */
  function onCheck(event, key) {
    handleCheck("foerderung", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   *
   * @param item
   * @param temporaryItem
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Förderungen
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasFreifinanziert, hasTemporaryFreifinanziert)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "freifinanziert")} type="checkbox" />

            <span className="ml-3">{fundingFilterLabels.freifinanziert}</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasGefoerdert, hasTemporaryGefoerdert)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "gefoerdert")} type="checkbox" />

            <span className="ml-3">{fundingFilterLabels.gefoerdert}</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasGemeinde, hasTemporaryGemeinde)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "gemeinde")} type="checkbox" />

            <span className="ml-3">{fundingFilterLabels.gemeinde}</span>
          </label>
        </div>
      </div>
    </div>
  );
}
