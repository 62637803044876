import ProjectFilterTags from "~/src/features/filter-tags/project-filter-tags";
import LegendBox from "~/src/features/legend-box";
import ProjectsMap from "~/src/features/maps/projects-map";
import ProjectCount from "~/src/features/project-count";
import StatsBox from "~/src/features/stats-box";

import useProjectQueryParams from "~/src/hooks/use-project-query-params";

import LogoColor from "~/src/ui/logos/logo-color";

/**
 *
 * @example
 */
const PrintOverviewPage = () => {
  const { query } = useProjectQueryParams();

  return (
    <>
      <style>
        {`
          *:not(html, #print, #print *) {
            all: unset !important;
          }
          *:not(html, html > body, html > body > div#root, #print, #print *) {
            display: none !important;
          }
        `}
      </style>

      <div className="mx-auto my-0 w-[200mm] space-y-16 space-y-reverse bg-white" id="print">
        <div className="mb-16 mt-2 flex w-full flex-col items-center gap-4">
          <LogoColor />

          <ProjectCount />

          <StatsBox />

          <ProjectFilterTags query={query} />

          <div className="w-full break-inside-avoid">
            <ProjectsMap
              forExport
              isSimple
              className="mb-4 h-[150mm] rounded border border-gray-200 "
            />

            <LegendBox query={query} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintOverviewPage;
