import { MapPinIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";

/**
 * @typedef {object} City
 * @property {number} zipcode - The zipcode.
 * @property {string} name - The name.
 */

/**
 * @typedef {object} Street
 * @property {string} name - The name.
 */

/**
 * @typedef {object} Location
 * @property {City} [city] - The city.
 * @property {string} housenumber - The housenumber.
 * @property {Street} street - The street.
 */

/**
 * @typedef {object} ProjectLocationProps
 * @property {string} [className] - The component classes.
 * @property {Location} location - The project location.
 * @property {"normal"|"small"} [size] - The size of the component.
 */

/**
 * The project mini list component.
 *
 * @param {ProjectLocationProps} props - The component props.
 * @returns {JSX.Element|null} The rendered JSX element.
 * @example
 */
export default function ProjectLocation({
  className = "flex items-center text-gray-500 text-base print:text-xs gap-1.5 print:gap-[7px]",
  location: {
    cityName,
    housenumber,
    streetName,
    zipcodeZipcode
  } = {
    cityName: null,
    housenumber: null,
    streetName: null,
    zipcodeZipcode: null
  },
  size = "normal"
}) {
  const { query: { sort } } = useProjectQueryParams();

  return (
    <div className={className}>
      {
        size !== "small" && (
          <MapPinIcon className="mb-0.5 ml-0.5 size-4 shrink-0 text-gray-500 print:ml-px" />
        )
      }

      <p className="text-left leading-tight">
        <span className={cn({ "text-primary font-semibold": sort === "zipcode" })}>
          {zipcodeZipcode}{" "}
        </span>

        <span>{cityName}, </span>

        <span>{streetName} </span>

        <span>{housenumber}</span>
      </p>
    </div>
  );
}
