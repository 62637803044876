import classNames from "classnames";
import { forwardRef } from "react";

import { errorMessage } from "~/src/modules/labels";

import AlertIcon from "../../icons/alert-icon";

/**
 *
 * @param props
 * @param props.data
 * @param props.span
 */
const ChartError = forwardRef(({
  className, span = 1, type
}, reference) => (
  <div
    ref={reference}
    className={classNames(
      "flex items-center px-4 gap-2 rounded border border-gray-200 bg-white shadow-sm justify-center",
      {
        "col-span-1": span === 1,
        "col-span-2": span === 2,
        "py-4": type !== "offer" || type !== "sale",
        "py-16": type === "offer" || type === "sale"
      },
      className
    )}
  >
    <AlertIcon className="size-5 text-gray-500" color="text-gray-400" />

    <h2 className="">{errorMessage.statsChart}</h2>
  </div>
));

export default ChartError;
