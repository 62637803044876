import { handleCheck, handleCheckedStatus } from "~/src/modules/search-filter-helper";

/**
 *
 * @param root0 - The root object
 * @param root0.query - The root object
 * @param root0.tempQuery - The root object
 * @param root0.update - The root object
 * @example
 */
export default function RechtsformSearchFilter({
  query, tempQuery, update
}) {
  const items = query?.split(",") || [];
  const temporaryItems = tempQuery?.split(",") || [];

  const hasBuyable = items.includes("buyable");
  const hasRentable = items.includes("rentable");
  const hasBuyableRentable = items.includes("buyable_rentable");
  const hasUnknown = items.includes("unknown");

  const hasTemporaryBuyable = temporaryItems.includes("buyable");
  const hasTemporaryRentable = temporaryItems.includes("rentable");
  const hasTemporaryBuyableRentable = temporaryItems.includes("buyable_rentable");
  const hasTemporaryUnknown = temporaryItems.includes("unknown");

  /**
   *
   * @param event
   * @param key
   * @example
   */
  function onCheck(event, key) {
    handleCheck("rechtsform", event, tempQuery, items, temporaryItems, key, update);
  }

  /**
   *
   * @param item
   * @param temporaryItem
   * @example
   */
  function checked(item, temporaryItem) {
    return handleCheckedStatus(query, item, tempQuery, temporaryItem);
  }

  return (
    <div className="py-8">
      <p className="text-xl font-medium">
        Rechtsform
      </p>

      <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-4 font-light sm:grid-cols-2">
        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasBuyable, hasTemporaryBuyable)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "buyable")} type="checkbox" />

            <span className="ml-3">Eigentum</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasRentable, hasTemporaryRentable)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "rentable")} type="checkbox" />

            <span className="ml-3">Miete</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasBuyableRentable, hasTemporaryBuyableRentable)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "buyable_rentable")} type="checkbox" />

            <span className="ml-3">Eigentum & Miete</span>
          </label>
        </div>

        <div>
          <label className="inline-flex cursor-pointer items-center">
            <input checked={checked(hasUnknown, hasTemporaryUnknown)} className="size-6 rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0" onChange={(e) => onCheck(e, "unknown")} type="checkbox" />

            <span className="ml-3">nicht bekannt</span>
          </label>
        </div>
      </div>
    </div>
  );
}
