import {
  CubeIcon,
  KeyIcon,
  UsersIcon
} from "@heroicons/react/24/solid";
import { Tooltip } from "react-tooltip";

import { companyRoleLabels } from "~/src/modules/labels";

import useMediaQuery from "~/src/hooks/use-media-query";

/**
 *
 * @param props - The root object
 * @param props.role - The root object
 * @param props.automated - The root object
 * @example
 */
export default function ProjectCompany({ automated, role }) {
  const isPrint = useMediaQuery("print");

  return (
    <div className="print:flex">
      <div
        className="flex items-center gap-2 text-sm text-gray-500 print:text-xs"
        data-tooltip-content={companyRoleLabels[automated ? "seller" : role.name]}
        data-tooltip-id={`tooltip-company-role-${role.name}`}
        data-tooltip-place="left"
      >
        {role.name === "builder" && (
          <>
            <CubeIcon className="mt-0.5 size-4 shrink-0 focus:outline-none print:leading-4" />

            <Tooltip
              className="z-50"
              delayShow={500}
              id="tooltip-company-role-builder"
              positionStrategy="fixed"
            />
          </>
        )}

        {role.name === "owner" && (
          <>
            <KeyIcon className="mt-0.5 size-4 shrink-0 focus:outline-none print:leading-4" />

            <Tooltip
              className="z-50"
              delayShow={500}
              id="tooltip-company-role-owner"
              positionStrategy="fixed"
            />
          </>
        )}

        {role.name === "marketer" && (
          <>
            <UsersIcon className="mt-0.5 size-4 shrink-0 focus:outline-none print:leading-4" />

            <Tooltip
              className="z-50"
              delayShow={500}
              id="tooltip-company-role-marketer"
              positionStrategy="fixed"
            />
          </>
        )}

        <span className="mt-0.5  font-light leading-5 text-gray-500 print:leading-4" >
          {role.private ? "Privat" : role.company?.name}
        </span>
      </div>
    </div>
  );
}
