import Spinner from "~/src/ui/loading/spinner";

/**
 *
 * @param root0 - The root object
 * @param root0.children - The root object
 * @example
 */
const LoadingNotification = ({ children }) => (
  <div className="flex h-full items-end justify-end bg-white shadow-lg">
    <div className="pointer-events-auto w-full max-w-md rounded border-t-4 border-t-primary">
      <div className="p-4">
        <div className="flex items-center">
          <Spinner />

          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-800">
              {children}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LoadingNotification;
