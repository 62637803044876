import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

/**
 *
 * @param root0 - The root object
 * @param root0.children - The root object
 * @param root0.project - The root object
 * @param root0.target - The root object
 * @param root0.backlink - The root object
 * @param root0.className - The root object
 * @param root0.inNewTab - The root object
 * @example
 */
export default function ProjectLink({
  backlink = null, children, className, inNewTab = false, project, target
}) {
  const { pathname, search } = useLocation();

  const linkOptions = {
    pathname: `/projekte/${project.slug}`,
    state: {
      backlink,
      pathname,
      project,
      search
    }
  };

  return (
    <Link className={cn("focus:outline-none detail-link", className)} target={(inNewTab) ? "_blank" : target} to={linkOptions}>
      {children}
    </Link>
  );
}
