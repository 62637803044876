import { ListChecksIcon, ListTodoIcon } from "lucide-react";
import { forwardRef } from "react";
import { Tooltip } from "react-tooltip";

import useMediaQuery from "~/src/hooks/use-media-query.js";

/**
 * @typedef {object} SelectOnlyMarkedProjectsProps
 * @property {boolean} [disabled] - Whether the filter is disabled.
 * @property {Function} handleCheck - The check event handler.
 * @property {boolean} onlyMarkedProjects - The selection status.
 * @property {"normal"|"small"} [size] - The size of the filter.
 */

const SelectOnlyMarkedProjects = forwardRef(

  /**
   * The select only marked projects filter component.
   *
   * @param {SelectOnlyMarkedProjectsProps} props - The component props.
   * @param forwardedReference
   * @returns {JSX.Element|null} The rendered JSX element.
   * @example
   */
  (
    {
      disabled,
      handleCheck,
      onlyMarkedProjects,
      size = "normal"
    },
    forwardedReference
  ) => {
    const isMobile = useMediaQuery("(max-width: 1023px)");

    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event
     * @example
     */
    const handleCheckbox = (event) => {
      handleCheck(event.target.checked);
    };

    // Nur markierte Projekte anzeigen

    switch (size) {
      case "normal": return (
        <label
          className="flex cursor-pointer items-start gap-2 pl-0.5"
          htmlFor="onlyMarked"
          ref={forwardedReference}
        >
          <input
            checked={onlyMarkedProjects}
            className="mt-0.5 size-4 cursor-pointer rounded border border-gray-300 text-gray-500 shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-default disabled:opacity-50"
            disabled={disabled}
            id="onlyMarked"
            onChange={handleCheckbox}
            type="checkbox"
          />

          <span className="hidden font-medium text-gray-500 lg:block">Nur markierte Projekte anzeigen</span>

          <span className="font-medium text-gray-500 lg:hidden">Nur markierte Projekte</span>
        </label>
      );
      case "small": return (
        <>
          <button
            className="flex h-6 w-8 cursor-pointer items-center justify-center rounded-sm border border-gray-200 bg-white p-2 text-gray-500 hover:bg-gray-50 lg:h-[1.875rem] lg:w-9 lg:p-[0.3rem]"
            disabled={disabled}
            id="only-marked-projects-button"
            type="button"
            onClick={() => {
              handleCheck(!onlyMarkedProjects);
            }}
          >
            {
              onlyMarkedProjects
                ? (
                  <ListChecksIcon className="w-full lg:h-full" />
                )
                : (
                  <ListTodoIcon className="w-full lg:h-full" />
                )
            }
          </button>

          {
            !isMobile && (
              <Tooltip
                anchorSelect="#only-marked-projects-button"
                className="z-50"
                content="Nur markierte Projekte anzeigen"
                delayHide={0}
                delayShow={500}
                id="tooltip-only-marked-projects-button"
                place="bottom"
              />
            )
          }
        </>
      );

      default: return null;
    }
  }
);

SelectOnlyMarkedProjects.displayName = "SelectOnlyMarkedProjects";

export default SelectOnlyMarkedProjects;
