import useRegions from "~/src/hooks/use-regions";

import DefaultFilterTag from "~/src/ui/search-filter-tags/default-filter-tag";

/**
 *
 * @param root0 - The root object
 * @param root0.queryRegions - The root object
 * @param root0.submit - The root object
 * @example
 */
export default function ShareDealRegionFilterTag({ queryRegions = [], submit }) {
  const {
    isLoading, regions, states
  } = useRegions();

  if (queryRegions.length === 0 || isLoading) {
    return null;
  }

  /**
   *
   * @param regionIds
   * @example
   */
  function handleClick(regionIds) {
    const newRegionQueryItems = queryRegions.filter((item) => !regionIds.includes(String(item)));

    submit({ regions: newRegionQueryItems.length > 0 ? newRegionQueryItems : undefined });
  }

  const regionStates = queryRegions?.sort((a, b) => a - b).map((queryRegion) => {
    const region = regions?.find((region) => Number(region.regionCode) === queryRegion);

    return region || null;
  });

  const grouped = regionStates?.reduce((accumulator, regionState) => {
    if (accumulator[regionState?.stateIdentifier]) {
      accumulator[regionState?.stateIdentifier].regions.push(regionState);
    }
    else {
      accumulator[regionState?.stateIdentifier] = {
        code: regionState?.stateIdentifier,
        name: regionState?.stateName,
        numberOfRegions: regions.filter((region) => region.stateIdentifier === regionState.stateIdentifier).length,
        regions: [regionState]
      };
    }

    return accumulator;
  }, {});

  const tags = Object.keys(grouped).map((key) => {
    const group = grouped[key];

    if (group.numberOfRegions === group.regions.length) {
      return (
        <DefaultFilterTag handleClick={() => handleClick(group.regions.map((region) => region.regionCode))} key={`filter_region_state_${group.code}`}>
          <span>{group.name}</span>
        </DefaultFilterTag>
      );
    }

    return group.regions.map((region) => (
      <DefaultFilterTag handleClick={() => handleClick([region.regionCode])} key={`filter_region_${region.regionId}`}>
        <span>{region?.regionName}</span>
      </DefaultFilterTag>
    ));
  });

  return (
    <>
      {tags}
    </>
  );
}
