import {
  createContext, useCallback, useState
} from "react";

export const ModalContext = createContext({
  active: false,
  hideModal: () => {},
  showModal: () => {}
});

/**
 *
 * @param root0 - The root object
 * @param root0.children - The root object
 * @example
 */
const FilterProvider = ({ children }) => {
  const [active, setActive] = useState(false);

  const showModal = () => setActive(true);
  const hideModal = () => setActive(false);

  const contextValue = {
    active,
    hideModal: useCallback(() => hideModal(), []),
    showModal: useCallback(() => showModal(), [])
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export default FilterProvider;
