import { Temporal } from "@js-temporal/polyfill";
import { Line, ResponsiveLine } from "@nivo/line";

import useMediaQuery from "~/src/hooks/use-media-query";
/* eslint-disable consistent-return */
/* eslint-disable no-magic-numbers */
import * as React from "react";
import { useEffect, useState } from "react";

import { getActivatedColor } from "../helpers";

const {
  PlainYearMonth
} = Temporal;

const numberFormatter = new Intl.NumberFormat("de-AT");

const monthFormatterShort = new Intl.DateTimeFormat("de-AT", {
  calendar: "iso8601",
  month: "short",
  year: "numeric"
});

const monthFormatterLong = new Intl.DateTimeFormat("de-AT", {
  calendar: "iso8601",
  month: "long",
  year: "numeric"
});

const meterFormatter = new Intl.NumberFormat("de-AT", {
  style: "unit",
  unit: "meter"
});

const currencyFormatter = new Intl.NumberFormat("de-AT", {
  currency: "EUR",
  style: "currency"
});

const formatNumber = (number) => numberFormatter.format(number).replaceAll("\u00A0", ".");

const formatMonthShort = (date) => monthFormatterShort.format(date);
const formatMonthLong = (date) => monthFormatterLong.format(date);

const formatSquareMeters = (number) => `${meterFormatter.format(number)}²`;
const formatSquareMetersRange = (numberA, numberB) => `${meterFormatter.formatRange(numberA, numberB)}²`;

const formatEuro = (number) => currencyFormatter.format(number);

const formatEuroPerSquareMeter = (number) => `${formatEuro(number)}/m²`;

const PointsDetailLayer = ({
  currentSlice,
  xScale,
  yScale
}) => {
  if (currentSlice !== null) {
    const currentXPosition = currentSlice.points[0].data.x;
    const currentYPosition = currentSlice.points.map((point) => ({
      y: point.data.y
    }));

    if (currentXPosition && Object.keys(currentYPosition).length > 0) {
      return Object.entries(currentYPosition).map(([key, yPosition], index) => (
        <React.Fragment key={`circle_${key}_${index}`}>
          <circle cx={xScale(currentXPosition)} cy={yScale(yPosition.y)} key={`circle_out_${key}_${index}`} r="5" />

          <circle cx={xScale(currentXPosition)} cy={yScale(yPosition.y)} fill="#FFFFFF" key={`circle_in_${key}_${index}`} r="3" />
        </React.Fragment>
      ));
    }
  }

  return null;
};

const getTooltip = ({
  slice: {
    points
  }
}) => {
  const xValue = points.find((point) => point.data.x);

  return (
    <div className="flex flex-col gap-1 rounded border bg-white p-2 text-sm">
      <span className="text-xs font-bold text-gray-600">{formatMonthLong(xValue.data.x)}</span>

      {
        points.map((point, index) => (
          <div className="flex items-center gap-2" key={index}>
            <div
              className="size-4 rounded-full"
              style={{
                backgroundColor: point.serieColor
              }}
            />

            <span>
              {`${point.serieId}m²: ${formatEuroPerSquareMeter(point.data.y)} Einheiten: ${formatNumber(point.data.count)}`}
            </span>
          </div>
        ))

      }
    </div>
  );
};

const getXScale = ({
  min: xMin = "auto",
  max: xMax = "auto",
  type: xType
}) => {
  switch (xType) {
    case "month":
      return {
        format: "%Y-%m",
        precision: "month",
        tickValues: "every month",
        type: "time",
        useUTC: false
      };
    case "squareMeters":
      return {
        min: xMin,
        max: xMax,
        tickSize: 5,
        type: "linear"
      };

    default:
      return {
        min: xMin,
        max: xMax,
        tickSize: 5,
        type: "linear"
      };
  }
};

const getAxisBottom = (xType, isMobile) => {
  switch (xType) {
    case "month":
      return {
        format: (date) => {
          const monthNumber = date.toTemporalInstant().toZonedDateTimeISO("Europe/Vienna").month;

          const interval = isMobile ? 6 : 3;

          return (monthNumber - 1) % interval === 0
            ? formatMonthShort(date)
            : "";
        },
        legend: "Monat",
        legendOffset: 65,
        legendPosition: "middle",
        tickPadding: 5,
        tickRotation: 45,
        tickSize: 5,
        tickValues: "every month"
      };
    case "squareMeters":
      return {
        format: (number) => {
          const interval = isMobile ? 50 : 10;

          return number % interval === 0
            ? formatSquareMeters(number)
            : "";
        },
        legend: "Fläche",
        legendOffset: 45,
        legendPosition: "middle",
        renderTick: ({
          value, x, y
        }) => {
          const interval = isMobile ? 50 : 10;

          const isStrong = value % interval === 0;

          return (
            <g transform={`translate(${x},${y})`}>
              <line
                opacity={0.75}
                stroke="currentColor"
                y1={0}
                strokeWidth={isStrong
                  ? 1
                  : 0.5}
                y2={isStrong
                  ? 6
                  : 4}
              />

              <text fontSize={10} textAnchor="middle" y={20}>
                {
                  value % interval === 0
                    ? formatSquareMeters(value)
                    : ""
                }
              </text>
            </g>
          );
        },
        tickPadding: 5,
        tickRotation: 0,
        tickSize: 5,
        tickValues: Array.from({ length: 41 })
          .fill()
          .map((empty, index) => index * 5)
      };
    default:
      return {
      };
  }
};

const getXFormat = (xType) => {
  switch (xType) {
    case "month":
      return "time:%Y-%m";

    default:
  }
};

const getProperties = ({
  activeAreaGroups,
  data,
  forPrint,
  isMobile,
  theme,
  withArea,
  withCategories,
  x,
  x: {
    type: xType
  },
  y: {
    min: yMin = "auto",
    max: yMax = "auto"
  } = {
    min: "auto",
    max: "auto"
  }
}) => {
  const commonProperties = {
    animate: true,
    axisBottom: getAxisBottom(xType, isMobile),
    axisLeft: {
      format: (value) => `${value}`,
      legend: data?.[0]?.label,
      legendOffset: -50,
      legendPosition: "middle",
      tickPadding: 5,
      tickRotation: -45,
      tickSize: 5
    },
    colors: (dataPoint) => {
      if (activeAreaGroups) {
        return getActivatedColor(dataPoint, activeAreaGroups);
      }

      return dataPoint.color;
    },
    data,
    enableSlices: "x",
    isInteractive: true,
    margin: {
      bottom: 80,
      left: 60,
      right: 48,
      top: 10
    },
    pointBorderColor: { from: "serieColor" },
    pointBorderWidth: 0,
    pointColor: (dataPoint) => {
      if (activeAreaGroups) {
        return getActivatedColor(dataPoint, activeAreaGroups);
      }

      return dataPoint.color;
    },
    pointLabelYOffset: -12,
    pointSize: 4,
    theme,
    xFormat: getXFormat(xType),
    xScale: getXScale(x),
    yScale: {
      min: yMin,
      max: yMax,
      reverse: false,
      stacked: false,
      type: "linear"
    }
  };

  const backgroundLayers = [
    "grid",
    "markers",
    "axes",
    "areas"
  ];

  const middleLayers = [
    "crosshair",
    "points",
    "lines"
  ];

  if (!forPrint) {
    middleLayers.push(PointsDetailLayer);
  }

  const foregroundLayers = [
    "slices",
    "mesh",
    "legends"
  ];

  commonProperties.layers = [
    ...backgroundLayers,
    ...middleLayers,
    ...foregroundLayers
  ];

  if (!forPrint) {
    commonProperties.sliceTooltip = getTooltip;
  }

  return commonProperties;
};

/**
 *
 * @param props - The root object
 * @param props.data - The root object
 * @param props.withArea - The root object
 * @param props.withCategories - The root object
 * @param props.xType - The root object
 * @param props.x - The root object
 * @param props.y - The root object
 * @param props.activeAreaGroups - The root object
 * @param props.forPrint - The root object
 * @example
 */
export default function MultiLineChart({
  activeAreaGroups,
  data = [],
  forPrint,
  withArea = false,
  withCategories = false,
  x,
  y,
  ...properties
}) {
  const isPrint = useMediaQuery("print");
  const isMobile = useMediaQuery("(max-width: 1023px)") || isPrint;

  const theme = {
    axis: {
      legend: {
        text: {
          fill: "black",
          fontSize: isPrint ? 12 : 16
        }
      }
    }
  };

  const ChartComponent = isPrint ? Line : ResponsiveLine;

  const [animatedData, setAnimatedData] = useState([]);

  useEffect(() => {
    if (!isPrint) {
      // Delay setting the animated data to create the initial animation
      const timeoutId = setTimeout(() => {
        setAnimatedData(data);
      }, 1);

      /**
       * Cleanup function
       *
       * @example
       */
      return () => clearTimeout(timeoutId);
    }
  }, [data]);

  const chartProperties = getProperties({
    activeAreaGroups,
    animate: !isPrint,
    data: isPrint ? data : animatedData,
    forPrint,
    isMobile,
    theme,
    withArea,
    withCategories,
    x,
    y
  });

  if (isPrint ? data.length === 0 : animatedData.length === 0) {
    return null;
  }

  return (
    <ChartComponent
      {...chartProperties}
      {...(isPrint && {
        height: 320,
        width: 755.905_5
      })}
    />
  );
}
