import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { priceLabels, sortLabels } from "~/src/modules/labels";

/**
 *
 * @param root0 - The root object
 * @param root0.sortBy - The root object
 * @param root0.onSubmit - The root object
 * @example
 */
export default function SortWatchlistProjectsDropdown({ onSubmit, sortBy = "name" }) {
  const sortLabel = /^(?:avg|gka)/u.test(sortBy)
    ? priceLabels[sortBy.replace("avg", "average").replace("PreisEigennutzer", "OfferPrice").replace("PreisVerkauf", "SalePrice")]
    : sortLabels[sortBy];

  return (
    <Menu as="div" className="relative -mt-0.5">
      <Menu.Button className="inline-flex h-10 w-full items-center justify-center border-b border-secondary-light text-base font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        {sortLabel}

        <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-0.5 size-5 text-gray-500" />
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 -mt-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="w-full py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "name" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "name",
                  "text-gray-700": !active
                })}
              >
                <span>Projektname</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "finishYear" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "finishYear",
                  "text-gray-700": !active
                })}
              >
                Fertigstellungsjahr (Bau)
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "wohnungen" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "wohnungen",
                  "text-gray-700": !active
                })}
              >
                Anzahl Wohneinheiten
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "plz" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "plz",
                  "text-gray-700": !active
                })}
              >
                PLZ
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "gka" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "gka",
                  "text-gray-700": !active
                })}
              >
                {priceLabels.gka}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "avgPreisEigennutzer" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "avgPreisEigennutzer",
                  "text-gray-700": !active
                })}
              >
                {priceLabels.averageOfferPrice}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "avgRent" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "avgRent",
                  "text-gray-700": !active
                })}
              >
                {priceLabels.averageRent}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => onSubmit({ sortBy: "avgPreisVerkauf" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": active,
                  "font-bold": sortBy === "avgPreisVerkauf",
                  "text-gray-700": !active
                })}
              >
                {priceLabels.averageSalePrice}
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}
