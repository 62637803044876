/* eslint-disable no-empty-function */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */

import { Temporal } from "@js-temporal/polyfill";
import { snakeCase } from "lodash-es";
import { stringify } from "query-string";
import {
  useCallback, useEffect, useMemo
} from "react";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

import { fetcher } from "~/src/modules/api.js";

import { getPolygons } from "~/src/schema/filters.js";

import useStore from "./use-store.js";

const { Now } = Temporal;

/**
 *
 * @param {string} sort
 * @returns
 * @example
 */
const getSortPath = (sort) => {
  switch (sort) {
    case "radius":
      return "locations[0]";
    case "zipcode":
      return "locations[0].zipcodeZipcode";
    default:
      return snakeCase(sort ?? "name");
  }
};

/**
 *
 * @param {string} sort
 * @returns
 * @example
 */
const getSortType = (sort) => {
  switch (sort) {
    case "radius":
      return "fromStreet";
    default:
      return "default";
  }
};

/**
 *
 * @param {object} options - The options object.
 * @param {boolean} [options.isSimple] - Whether the project list is simple.
 * @param {object} options.query - The query object.
 * @param {string} options.query.polygon - The polygon.
 * @param {string} options.query.sort - The sort.
 * @param {boolean} [options.shouldFetch] - Whether the project list should be fetched.
 * @param {"normal"|"small"} [options.size] - The size of the project list.
 * @param {object} [options.swrOptions] - The SWR options.
 * @param options.perPage - The root object
 * @returns
 * @example
 */
const useProjectList = ({
  isSimple = false,
  perPage = 50,
  query: {
    polygon,
    sort,
    ...query
  },
  shouldFetch = true,
  size = "normal",
  swrOptions
}) => {
  const selectionMutate = useStore((state) => state.selectionMutate);

  if (!isSimple) {
    const transformedQuery = useMemo(() => ({
      ...query,
      polygon: getPolygons(polygon),
      sortPath: getSortPath(sort),
      sortType: getSortType(sort)
    }), [
      JSON.stringify(query),
      polygon,
      sort
    ]);

    if (!transformedQuery.activeBBox) {
      delete transformedQuery.bBox;
      delete transformedQuery.mapZoom;
      delete transformedQuery.centerLat;
      delete transformedQuery.centerLng;
    }

    switch (size) {
      case "normal": {
        const {
          data,
          data: {
            payload: {
              selectionSlugs = [],
              selectionType = "addAll"
            } = {}
          } = {},
          error,
          isValidating,
          mutate
        } = useSWR(() => (shouldFetch ? `/project-list?${stringify(transformedQuery, { arrayFormat: "comma" })}` : null), fetcher, swrOptions);

        if (data?.payload?.projects) {
          for (const slug of selectionSlugs) {
            const index = data?.payload?.projects?.findIndex((project) => project.slug === slug);

            if (index !== -1) {
              if (selectionType === "addAll") {
                data.payload.projects[index].unselected = true;
              }
              else {
                data.payload.projects[index].selected = true;
              }
            }
          }
        }

        return {
          isError: error,
          isLoading: !error && !data,
          isValidating,
          mutate,
          projects: data?.payload?.projects,
          selectionSlugs,
          selectionStatus: data?.payload?.selectionStatus,
          selectionType: data?.payload?.selectionType,
          total: data?.payload?.total,
          updateTime: Now.instant().epochMilliseconds
        };
      }

      case "small": {
        const getKey = useCallback(

          /**
           *
           * @param {number} pageIndex
           * @param {{payload:{projects:object[]}}} previousPageData
           * @returns
           * @example
           */
          (pageIndex, previousPageData) => {
            if (previousPageData && previousPageData.payload.projects.length === 0) {
              return null;
            }

            const pageQuery = {
              ...transformedQuery,
              page: pageIndex + 1,
              perPage
            };

            return shouldFetch
              ? `/project-list?${stringify(pageQuery, { arrayFormat: "comma" })}`
              : null;
          },
          [
            JSON.stringify(transformedQuery),
            shouldFetch,
            selectionMutate
          ]
        );

        const {
          data,
          error,
          isValidating,
          mutate,
          setSize,
          size: swrSize
        } = useSWRInfinite(getKey, fetcher, swrOptions);

        useEffect(() => {
          setSize(1);
        }, [
          transformedQuery,
          setSize,
          size
        ]);

        // Process the data
        const projects = data
          ? data.flatMap((page) => {
            const {
              payload: {
                projects: pageProjects = [],
                selectionSlugs = [],
                selectionType = "addAll"
              } = {}
            } = page;

            const clonedPageProjects = structuredClone(pageProjects);

            // Handle selection for each page
            for (const slug of selectionSlugs) {
              const index = clonedPageProjects.findIndex((project) => project.slug === slug);

              if (index !== -1) {
                if (selectionType === "addAll") {
                  clonedPageProjects[index].unselected = true;
                }
                else {
                  clonedPageProjects[index].selected = true;
                }
              }
            }

            return clonedPageProjects;
          })
          : [];

        const defaultSelectionSlugs = /**@type {const} */ ([]);
        const defaultSelectionType = "addAll";
        const defaultSelectionStatus = null;
        const defaultTotal = undefined;

        const selectionSlugs = data && data.length > 0
          ? data[0].payload.selectionSlugs || defaultSelectionSlugs
          : defaultSelectionSlugs;
        const selectionType = data && data.length > 0
          ? data[0].payload.selectionType || defaultSelectionType
          : defaultSelectionType;
        const selectionStatus = data && data.length > 0
          ? data[0].payload.selectionStatus || defaultSelectionStatus
          : defaultSelectionStatus;
        const total = data && data.length > 0
          ? data[0].payload.total || defaultTotal
          : defaultTotal;
        const isLoadingInitialData = !data && !error;
        const isLoadingMore = (
          isLoadingInitialData ||
          (swrSize > 0 && data && data[swrSize - 1] === undefined)
        );
        const isReachingEnd = total
          ? projects.length >= total.totalProjects
          : false;

        return {
          isError: error,
          isLoading: isLoadingInitialData,
          isLoadingMore,
          isReachingEnd,
          isValidating,
          mutate,
          projects: projects.length === 0 ? undefined : projects,
          selectionSlugs,
          selectionStatus,
          selectionType,
          setSize,
          size: swrSize,
          total,
          updateTime: Now.instant().epochMilliseconds
        };
      }

      // no default
    }
  }

  return {
    isError: null,
    isLoading: null,
    isValidating: null,
    mutate: () => {},
    projects: null,
    selectionSlugs: null,
    selectionStatus: null,
    selectionType: null,
    setSize: () => {},
    total: null,
    updateTime: Now.instant().epochMilliseconds
  };
};

export default useProjectList;
