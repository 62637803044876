import cn from "classnames";
import de from "date-fns/locale/de";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { forwardRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import { inputStyle } from "./styles.module.css";

dayjs.locale("de");
registerLocale("de", de);

/**
 *
 * @param root0 - The root object
 * @param root0.id - The root object
 * @param root0.name - The root object
 * @param root0.value - The root object
 * @param root0.touched - The root object
 * @param root0.error - The root object
 * @param root0.onChange - The root object
 * @param root0.onBlur - The root object
 * @param root0.isSubmitting - The root object
 * @param root0.placeholder - The root object
 * @param root0.autoFocus - The root object
 * @param root0.format - The root object
 * @param root0.minDate - The root object
 * @param root0.maxDate - The root object
 * @param root0.hasOtherDate - The root object
 * @example
 */
export default function DateMonthInput({
  id,
  minDate = new Date("2021-01-01"),
  maxDate = new Date("2121-01-01"),
  autoFocus = false,
  error,
  format,
  hasOtherDate = false,
  isSubmitting,
  name,
  onBlur,
  onChange,
  placeholder,
  touched,
  value
}) {
  const touchedAndNotEmpty = (Boolean(touched) === true) && value !== "";
  const showError = error && (isSubmitting || touchedAndNotEmpty);

  /**
   *
   * @param date
   * @example
   */
  function handleChange(date) {
    let direction = "asc";

    if (!hasOtherDate && name === "dateTo") {
      direction = "desc";
    }

    onChange({
      direction,
      [name]: dayjs(date).format("YYYY-MM-DD")
    });
  }

  const CustomInput = forwardRef(({ onClick, value }, reference) => (
    <button className="w-32 rounded border border-secondary-darker bg-secondary-dark py-0.5 text-center text-gray-600 hover:bg-secondary-darker focus:outline-none" onClick={onClick} ref={reference} type="button">
      <span className="text-sm font-medium">{placeholder}</span>
    </button>
  ));

  return (
    <>
      <div className="relative">
        <DatePicker
          showMonthYearPicker
          autoFocus={autoFocus}
          customInput={<CustomInput />}
          dateFormat="MM/yyyy"
          dropdownMode="select"
          id={id}
          locale="de"
          maxDate={maxDate}
          minDate={minDate}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          placeholderText={placeholder}
          popperClassName="month-picker"
          selected={value}
          className={cn(inputStyle, {
            "border-gray-300 focus:ring-gray-700 focus:border-gray-700": !showError
          })}
        />
      </div>
    </>
  );
}
