/**
 *
 * @example
 */
export default function UnitStatsSkeleton() {
  return (
    <div className="mt-6 space-y-4 md:grid md:grid-cols-6 md:gap-4 md:space-y-0">
      {Array.from({ length: 3 }).map((e, index) => (
        <div className="relative flex animate-pulse items-center justify-between rounded border border-gray-200 bg-white p-4 shadow-sm md:col-span-2" key={`stats_skeleton_${index}`}>
          <div className="flex items-center">
            <p className="size-5 rounded-full bg-gray-300" />

            <p className="ml-3 h-5 w-40 rounded bg-gray-300" />
          </div>

          <p className="h-5 w-32 rounded bg-gray-300" />
        </div>
      ))}
    </div>
  );
}
