/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-magic-numbers */

import { Bar, ResponsiveBar } from "@nivo/bar";
import {
  Fragment, useEffect, useState
} from "react";

import useMediaQuery from "~/src/hooks/use-media-query";

/**
 *
 * @param props - The root object
 * @param props.data - The root object
 * @param props.groupMode - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function BarChart({
  data = [],
  groupMode
}) {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const isPrint = useMediaQuery("print");

  const ChartComponent = isPrint ? Bar : ResponsiveBar;

  const [animatedData, setAnimatedData] = useState(data);
  const [highlightedBar, setHighlightedBar] = useState(null);

  // needs predefined color, otherwise nivo will complain and throw a non curcial error
  const [highlightedBarColor, setHighlightedBarColor] = useState("#FFFFFF");

  const theme = {
    axis: {
      legend: {
        text: {
          fill: "black",
          fontSize: isPrint ? 12 : 16
        }
      }
    }
  };

  const getXModifier = (value) => {
    switch (value) {
      case 2:
        return 11;
      case 3:
        return 14;
      case 4:
        return 18;
      case 5:
        return 25;
      default:
        return 15;
    }
  };

  const handleBarHover = (bar) => {
    const { id } = bar;

    const color = bar.data[`${id}Color`];

    setHighlightedBar(id);
    setHighlightedBarColor(color);
  };

  const mouseLeaveNode = (bar) => {
    if (bar !== null) {
      setHighlightedBar(null);
    }
  };

  useEffect(() => {
    if (!isPrint) {
      // Delay setting the animated data to create the initial animation
      const timeoutId = setTimeout(() => setAnimatedData(data), 1);

      /**
       * Cleanup function
       *
       * @example
       */
      return () => clearTimeout(timeoutId);
    }
  }, [data]);

  const getTooltip = (bar) => {
    const projectCount = bar.data.data[`${bar.data.id}_project`];

    return (
      <div className="flex flex-row justify-start gap-x-2 gap-y-1 overflow-hidden rounded border bg-white p-1" key={`bar_${bar.id}`}>
        <div className="flex items-center pt-[0.2rem] ">
          <svg className="size-3 self-start" viewBox="0 0 100 100">
            <circle cx={50} cy={50} fill={bar.color} r="50" />
          </svg>
        </div>

        <div className="flex flex-col justify-start">
          <span className="flex justify-start truncate text-xs">
            {`${bar.data.value} ${bar.data.value === 1 ? "Einheit" : "Einheiten"}`}
          </span>

          <span className="flex justify-start truncate text-xs">
            {`aus ${projectCount} ${projectCount === 1 ? "Projekt" : "Projekten"}`}
          </span>
        </div>

      </div>
    );
  };

  const TotalLayer = (...properties) => {
    if (groupMode === "stacked") {
      const {
        bars,
        innerHeight,
        innerWidth
      } = properties[0];

      if (bars.length > 0) {
        const total = bars.reduce((sum, bar) => {
          const { data, key } = bar;
          const year = key.split(".")[1];

          if (!sum[year]) {
            sum[year] = {
              count: 0,
              height: 0,
              width: 0,
              x: 0
            };
          }

          sum[year].count += data.value || 0;
          sum[year].height += bar.height || 0;
          sum[year].x = bar.x || 0;
          sum[year].width = bar.width || 0;

          return sum;
        }, {});

        return Object.entries(total).map(([year, values], index) => (
          <Fragment key={`total_${index}`}>
            <text
              fontSize={10}
              fontWeight="bold"
              height={30}
              width={values.width}
              y={(innerHeight - 2) - values.height}
              style={{
                filter: `
                  drop-shadow(1px 1px 0px white)
                  drop-shadow(1px -1px 0px white)
                  drop-shadow(-1px 1px 0px white)
                  drop-shadow(-1px -1px 0px white)
                `
              }}
              x={values.x + (values.width / 2) - (getXModifier(
                Math.floor(Math.log10(values.count))
              ))}
            >
              <tspan>{values.count}</tspan>
            </text>
          </Fragment>

        ));
      }
    }

    // Return null if groupMode is not "stacked" or bars.length is 0
    return null;
  };

  const TooltipLayer = isPrint
    // eslint-disable-next-line no-empty-function
    ? () => { }
    : (...properties) => {
      const width = Math.max(properties[0].width / 10, 150);
      const height = 42;

      return properties[0].bars
        .map((bar, index) => {
          if (bar.key.includes(highlightedBar)) {
            const x = bar.x - (width / 2) + (bar.width / 2);
            const y = 80;

            if (!bar.data.value) {
              return null;
            }

            return (
              <g key={`tooltip_bar_${index}`} style={{ pointerEvents: "none" }}>
                <line
                  stroke="black"
                  x1={bar.x + (bar.width / 2)}
                  x2={bar.x + (bar.width / 2)}
                  y1={y + (index % 2 * 60)}
                  y2={bar.absY - 10}
                />

                <foreignObject
                  height={height}
                  overflow="visible"
                  style={{ justifyContent: "end" }}
                  width={width}
                  x={x}
                  y={y + (index % 2 * 60)}
                >
                  {getTooltip(bar)}
                </foreignObject>
              </g>
            );
          }
        })

        .filter((value) => value !== undefined);
    };

  return (
    <ChartComponent

      animate={!isPrint}
      colors={({ id, data }) => data[`${id}Color`]}

      /* OWN PROPS BELOW */
      data={isPrint ? data : animatedData}
      enableLabel={false}
      groupMode={groupMode}
      indexBy="year"
      innerPadding={groupMode === "grouped" ? 4 : 0}
      onMouseEnter={(bar) => handleBarHover(bar)}
      onMouseLeave={(bar) => mouseLeaveNode(bar)}
      padding={0.1}
      theme={theme}
      // getTooltip(datum)}
      // eslint-disable-next-line no-empty-function
      tooltip={(datum) => { }}
      valueScale={{ type: "linear" }}
      axisBottom={{
        legend: "Jahr",
        legendOffset: isMobile ? 45 : 35,
        legendPosition: "middle",
        tickPadding: 5,
        tickRotation: isMobile ? 45 : 0,
        tickSize: 5
      }}
      axisLeft={{
        legend: "Anzahl Wohneinheiten",
        legendOffset: -50,
        legendPosition: "middle",
        tickPadding: 5,
        tickRotation: 0,
        tickSize: 5
      }}
      indexScale={{
        round: true,
        type: "band"
      }}
      keys={[
        "buyable_funded",
        "buyable_notFunded",
        "rentable_funded",
        "rentable_notFunded",
        "unknown_funded",
        "unknown_notFunded"
      ]}
      layers={[
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "lines",
        ...((isMobile && !isPrint) ? [] : [TotalLayer]),
        "bars",
        "mesh",
        "legends",
        ...(
          isMobile
            ? []
            : [TooltipLayer]
        )
      ]}
      margin={{
        bottom: 50,
        left: 60,
        right: 48,
        top: 10
      }}
      {...(isPrint && {
        height: 320,
        width: 755.905_5
      })}
    />
  );
}
