import {
  Dialog, DialogBackdrop, DialogPanel, TransitionChild
} from "@headlessui/react";
import { Fragment } from "react";

import useKeyPress from "~/src/hooks/use-key-press";

import CloseIcon from "~/src/ui/icons/close-icon";

import ProjectsMapTile from "../../projects-map-tile";

/**
 *
 * @param {object} props - The root object
 * @param {Array} props.projectSlugs - The root object
 * @param {boolean} props.show - The root object
 * @param {Function} props.hide - The root object
 * @example
 */
export default function MapSlide({
  hide, projectSlugs, show
}) {
  useKeyPress("Escape", hide);

  /**
   *
   * @example
   */
  function handleHide() {
    hide();
  }

  return (
    <Dialog
      className="fixed inset-0 z-[150] overflow-hidden"
      onClose={hide}
      open={show}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <DialogPanel
            transition
            className="pointer-events-auto relative w-screen max-w-2xl transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
          >
            <TransitionChild>
              <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                <button
                  className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={hide}
                >
                  <span className="sr-only">Close panel</span>

                  <CloseIcon aria-hidden="true" className="size-6" />
                </button>
              </div>
            </TransitionChild>

            <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
              <div className="relative flex-1">
                <div className="absolute inset-0 inset-x-4 sm:inset-x-6">
                  <ProjectsMapTile handleCheckbox={() => {}} isSimple={true} projectSlugs={projectSlugs} />
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
