/* eslint-disable camelcase */

/* eslint-disable import/max-dependencies */
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import dateFormatter from "~/src/modules/date-formatter.js";
import { formatEuro } from "~/src/modules/formatters.js";
import {
  constructionPhaseLabels, liegenschaftLabels, marketingPhaseLabels,
  priceLabels
} from "~/src/modules/labels.js";
import { getQueryObject } from "~/src/modules/query-helper.js";

import DeleteWatchlistItemModal from "~/src/features/delete-watchlist-item-modal/index.jsx";

import ProjectCompany from "~/src/ui/displays/project-company/index.jsx";
import ProjectLocation from "~/src/ui/displays/project-location/index.jsx";
import CheckmarkCircleIcon from "~/src/ui/icons/checkmark-circle-icon/index.jsx";
import EsgIcon from "~/src/ui/icons/esg-icon/index.jsx";
import ProjectLink from "~/src/ui/links/project-link/index.jsx";

const displayRechtsform = (project) => {
  if (project.rentable && project.buyable) {
    return "Eigentum, Miete";
  }
  if (project.rentable) {
    return "Miete";
  }
  if (project.buyable) {
    return "Eigentum";
  }

  return "nicht bekannt";
};

/**
 *
 * @param props - The root object
 * @param props.project - The root object
 * @param props.handleSingleMapProject - The root object
 * @param props.watchlist - The root object
 * @param props.mutateWatchlist - The root object
 * @param props.mutateWatchlists - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function, complexity
const WatchlistProjectListItem = ({
  handleSingleMapProject,
  mutateWatchlist,
  mutateWatchlists,
  project,
  watchlist
}) => {
  const location = useLocation();
  const [currentSortValue, setCurrentSortValue] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showMultipleLocations, setShowMultipleLocations] = useState(false);

  useEffect(() => {
    const query = getQueryObject(location.search);

    if (query && query.sortBy) {
      setCurrentSortValue(query.sortBy);
    }
    else {
      setCurrentSortValue(null);
    }
  }, [location]);

  const builders = project?.company_roles.filter((role) => role.name === "builder");
  const owners = project?.company_roles.filter((role) => role.name === "owner");
  const marketers = project?.company_roles.filter((role) => role.name === "marketer");

  const completed = project.timeline.current_construction_phase === "construction_phase_completed";
  let completedDate;

  if (completed) {
    completedDate = (project?.timeline?.construction_phase_completed_date !== null && project?.timeline?.construction_phase_completed_date_format !== null)
      ? dateFormatter(project?.timeline?.construction_phase_completed_date, project?.timeline?.construction_phase_completed_date_format)
      : project.finish_year;
  }

  if (!completed && project.construction_infos.estimated_finish_date) {
    completedDate = dateFormatter(
      project.construction_infos.estimated_finish_date,
      project.construction_infos?.estimated_finish_date_format
    );
  }

  const lastContractDate = project?.timeline?.last_contract_date
    ? dateFormatter(project.timeline.last_contract_date, "year")
    : null;

  return (
    <div className="relative" key={`project_${project.slug}`}>

      <DeleteWatchlistItemModal hideModal={() => setShowDelete(false)} mutateWatchlist={mutateWatchlist} mutateWatchlists={mutateWatchlists} project={project?.slug} show={showDelete} watchlist={watchlist?.uuid} />

      <div className="grid-cols-9 gap-x-2 px-2 pb-8 pt-5 sm:px-6 lg:grid">

        <div className="col-span-6 mt-1 flex flex-col items-start break-words lg:mt-0.5 xl:col-span-3 2xl:col-span-4">
          <ProjectLink backlink="watchlist" project={project}>
            <p className="inline-block font-medium text-primary sm:text-xl">
              <span className="border-b-2 border-primary hover:text-primary-dark">{project.name}</span>
            </p>
          </ProjectLink>

          <div className="mt-2">
            {project.locations.length > 1 && (
              <div className="flex">
                <button className="block hover:underline focus:outline-none" onClick={() => handleSingleMapProject(project, 0)} type="button">
                  <ProjectLocation location={project.locations.find(({ default_location }) => default_location) ?? project.locations[0]} />
                </button>

                {!showMultipleLocations && (
                  <button className="ml-2" onClick={() => setShowMultipleLocations(true)} type="button">
                    <ChevronDownIcon className="size-6 text-gray-500" />
                  </button>
                )}

                {showMultipleLocations && (
                  <button className="ml-2" onClick={() => setShowMultipleLocations(false)} type="button">
                    <ChevronUpIcon className="size-6 text-gray-500" />
                  </button>
                )}
              </div>
            )}

            <Transition
              as="div"
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={showMultipleLocations}
            >
              {
                project.locations.length > 1 && project.locations
                  .filter(({ default_location }) => !default_location)
                  .map((currentLocation, index) => (
                    <div className="flex" key={`multiple_location_${currentLocation.id}`}>
                      <button className="block hover:underline focus:outline-none" onClick={() => handleSingleMapProject(project, index)} type="button">
                        <ProjectLocation location={currentLocation} />
                      </button>
                    </div>
                  ))
              }
            </Transition>

            {project.locations.length <= 1 && project.locations.map((currentLocation, index) => (
              <div className="flex" key={`single_location_${currentLocation.id}`}>
                <button className="block hover:underline focus:outline-none" onClick={() => handleSingleMapProject(project, index)} type="button">
                  <ProjectLocation location={currentLocation} />
                </button>
              </div>
            ))}
          </div>

          <div className="mt-4 flex flex-col items-start overflow-hidden">
            {[
              ...owners,
              ...builders,
              ...marketers
            ].map((role, index) => <ProjectCompany automated={project?.automated} key={index} role={role} />)}
          </div>

        </div>

        <div className="col-span-3 mr-2 mt-10 lg:mt-2 xl:col-span-2">
          {project?.show_image_public && project?.image_file_name && (
            <ProjectLink backlink="projects" project={project}>
              <div
                className={cn("relative w-64 xl:w-48 2xl:w-64 pb-2/3 rounded-lg overflow-hidden", {
                  "h-40 xl:h-32 2xl:h-40": project?.image_landscape,
                  "h-64 xl:h-64 2xl:h-64": !project?.image_landscape
                })}
              >
                <img alt={project?.name} className={cn("absolute object-cover w-full h-full transition duration-500 transform", { "hover:scale-110": !project?.automated })} src={`https://immodeveloper.fra1.digitaloceanspaces.com/${project?.image_file_name}`} />
              </div>
            </ProjectLink>
          )}
        </div>

        <div className="col-span-4 mt-12 lg:mt-0 xl:col-span-4 xl:mt-1.5 2xl:col-span-3">
          <div className="flex items-start justify-start xl:justify-between">
            <div className="flex w-[min(100%,17.5rem)] items-center justify-between">
              <div
                className="-ml-1 flex items-center gap-2 text-sm text-gray-500"
              >
                <CheckmarkCircleIcon />

                <p className="text-sm sm:text-base">
                  {
                    project?.automated
                      ? `Historisches Projekt${lastContractDate ? ` - ${lastContractDate}` : ""}`
                      : (completedDate ?? "nicht bekannt")
                  }
                </p>

              </div>

              {
                (project?.project_certificates && project?.project_certificates.length > 0)
                  ? <EsgIcon />
                  : null
              }
            </div>

            <div className="ml-4 mt-0.5 xl:ml-4">
              <button className="flex items-center rounded border border-gray-400 bg-gray-50 px-2 py-0.5 text-xs text-gray-600 hover:bg-gray-100 focus:outline-none" onClick={() => setShowDelete(true)} type="button">
                <span>Projekt entfernen</span> <XMarkIcon className="-mr-0.5 ml-1 size-3 text-gray-600" />
              </button>
            </div>
          </div>

          <div className="mt-4 space-y-1">
            {(project?.timeline.current_construction_phase && project?.timeline.current_construction_phase !== "unknown") && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64 shrink-0">
                  <p className="font-semibold leading-4 text-gray-600">Bauphase:</p>
                </div>

                <div>
                  <p className="leading-4.5">{constructionPhaseLabels[project?.timeline.current_construction_phase || ""]}</p>
                </div>
              </div>
            )}

            {(project?.timeline.current_marketing_phase && project?.timeline.current_marketing_phase !== "unknown") && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64 shrink-0">
                  <p className="font-semibold leading-4 text-gray-600">Vermarktungsphase:</p>
                </div>

                <div>
                  <p className="leading-4">{marketingPhaseLabels[project?.timeline.current_marketing_phase || ""]}</p>
                </div>
              </div>
            )}
          </div>

          <div className="mt-5">

            {(project?.transaction_filter && liegenschaftLabels[project?.transaction_filter]) && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className="font-semibold text-gray-600">Liegenschaftsankauf:</p>
                </div>

                <div>
                  <p>{liegenschaftLabels[project.transaction_filter]}</p>
                </div>
              </div>
            )}

            <div className="flex flex-col sm:flex-row">
              <div className="w-64">
                <p className="font-semibold text-gray-600">Rechtsform:</p>
              </div>

              <div>
                {displayRechtsform(project)}
              </div>
            </div>

            {
              project.count_wohnungen !== 0 && (
                <div className="flex flex-col sm:flex-row">
                  <div className="w-64">
                    <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "wohnungen" })}>
                      {project.automated ? "Erfasste Transaktionen" : "Gesamtanzahl Wohneinheiten"}:
                    </p>
                  </div>

                  <div>
                    <p className={cn("", { "text-primary font-semibold": currentSortValue === "wohnungen" })}>
                      {project.count_wohnungen}
                    </p>
                  </div>
                </div>
              )
            }
          </div>

          {
            (project?.funding.not_funded || project?.funding.normal_funded) && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600")}>
                    Förderung:
                  </p>
                </div>

                <div>
                  {project?.funding.not_funded && project?.funding.normal_funded &&
                    <p>gemischt</p>}

                  {!project?.funding.not_funded && project?.funding.normal_funded &&
                    <p>gefördert</p>}

                  {project?.funding.not_funded && !project?.funding.normal_funded &&
                    <p>freifinanziert</p>}
                </div>
              </div>
            )
          }

          <div className="mt-5">
            {project?.gka && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "gka" })}>
                    {priceLabels.gka}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "gka" })}>
                    {formatEuro(project?.gka)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_offer_price_eigennutzer && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgPreisEigennutzer" })}>
                    {priceLabels.averageOfferPriceNormal}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgPreisEigennutzer" })}>
                    {formatEuro(project?.avg_offer_price_eigennutzer)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_offer_price_investor && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgPreisEigennutzer" })}>
                    {priceLabels.averageOfferPriceInvestorNet}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgPreisEigennutzer" })}>
                    {formatEuro(project?.avg_offer_price_investor)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_miete && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgRent" })}>
                    {priceLabels.averageRentNet}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgRent" })}>
                    {formatEuro(project?.avg_miete)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_rent_bk && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgRent" })}>
                    {priceLabels.averageRentTotal}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgRent" })}>
                    {formatEuro(project?.avg_rent_bk)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_sale_price_eigennutzer && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgPreisVerkauf" })}>
                    {priceLabels.averageSalePriceNormal}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgPreisVerkauf" })}>
                    {formatEuro(project?.avg_sale_price_eigennutzer)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_sale_price_investor && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgPreisVerkauf" })}>
                    {priceLabels.averageSalePriceInvestorNet}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgPreisVerkauf" })}>
                    {formatEuro(project?.avg_sale_price_investor)}/m²
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchlistProjectListItem;
