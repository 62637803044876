import CloseIcon from "~/src/ui/icons/close-icon";

/**
 *
 * @param root0 - The root object
 * @param root0.label - The root object
 * @param root0.onClick - The root object
 * @param root0.show - The root object
 * @example
 */
export default function SingleFilterTag({
  label, onClick, show
}) {
  if (!label || !show) {
    return null;
  }

  return (
    <button className="flex items-center gap-x-1 rounded bg-white px-1.5 py-0.5 text-xs text-secondary-darkest shadow hover:bg-gray-50 focus:outline-none" onClick={onClick} type="button">
      <span>{label}</span>

      <CloseIcon className="-mr-0.5 size-4 text-secondary-darkest" />
    </button>
  );
}
