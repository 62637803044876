import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { useEffect, useState } from "react";

import { displayRechtsform } from "~/src/lib/project-interface.js";

import dateFormatter from "~/src/modules/date-formatter.js";
import { formatEuro } from "~/src/modules/formatters.js";
import {
  constructionPhaseLabels,
  liegenschaftLabels,
  marketingPhaseLabels,
  priceLabels
} from "~/src/modules/labels.js";

import WatchlistDropdown from "~/src/features/watchlist-dropdown/index.jsx";

import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useStore from "~/src/hooks/use-store.js";

import ProjectCompany from "~/src/ui/displays/project-company/index.jsx";
import ProjectLocation from "~/src/ui/displays/project-location/index.jsx";
import CheckmarkCircleIcon from "~/src/ui/icons/checkmark-circle-icon/index.jsx";
import EsgIcon from "~/src/ui/icons/esg-icon/index.jsx";
import ProjectLink from "~/src/ui/links/project-link/index.jsx";

/**
 *
 * @param props - The root object
 * @param props.project - The root object
 * @param props.handleCheckbox - The root object
 * @param props.handleSingleMapProject - The root object
 * @param props.watchlists - The root object
 * @param props.mutateWatchlists - The root object
 * @param props.disabled - The root object
 * @param props.mainSelectionType - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function, complexity
const ProjectListItem = ({
  disabled,
  handleCheckbox,
  mutateWatchlists,
  project,
  watchlists
}) => {
  const mainSelectionType = useStore((state) => state.mainSelectionType);

  const evaluateSelectedState = () => {
    if (mainSelectionType === "addAll") {
      const { unselected } = project;

      return !(unselected);
    }
    else if (mainSelectionType === "removeAll") {
      const { selected } = project;

      return Boolean(selected);
    }
    else if (project.selected) {
      return project.selected;
    }
    else if (project.unselected) {
      return !project.unselected;
    }

    return true;
  };

  const { query: { sort } } = useProjectQueryParams();
  const [currentSortValue, setCurrentSortValue] = useState(null);
  const [showMultipleLocations, setShowMultipleLocations] = useState(false);
  const [checked, setChecked] = useState(evaluateSelectedState());

  useEffect(() => {
    setChecked(evaluateSelectedState());
  }, [JSON.stringify(project), mainSelectionType]);

  useEffect(() => {
    if (sort) {
      setCurrentSortValue(sort);
    }
    else {
      setCurrentSortValue(null);
    }
  }, [sort]);

  const builders = project?.company_roles.filter((role) => role.name === "builder");
  const owners = project?.company_roles.filter((role) => role.name === "owner");
  const marketers = project?.company_roles.filter((role) => role.name === "marketer");

  const handleSelect = (event) => {
    setChecked(event.target.checked);
    handleCheckbox(event.target.checked, project.slug);
  };

  const completed = project
    ?.timeline
    ?.current_construction_phase === "construction_phase_completed";

  let completedDate;

  if (completed) {
    completedDate = (project?.timeline?.construction_phase_completed_date !== null && project?.timeline?.construction_phase_completed_date_format !== null)
      ? dateFormatter(project?.timeline?.construction_phase_completed_date, project?.timeline?.construction_phase_completed_date_format)
      : project.finish_year;
  }

  if (!completed && project?.construction_infos?.estimated_finish_date) {
    completedDate = dateFormatter(
      project.construction_infos.estimated_finish_date,
      project.construction_infos?.estimated_finish_date_format
    );
  }

  const lastContractDate = project?.timeline?.last_contract_date
    ? dateFormatter(project.timeline.last_contract_date, "year")
    : null;

  return (
    <div className="relative border-b border-gray-200 last:border-b-0 last:pb-8" key={`project_${project.slug}`}>

      <div className="grid-cols-9 gap-x-2 p-4 lg:grid">

        <div
          className="col-span-6 flex flex-col items-start gap-2 break-words xl:col-span-3 2xl:col-span-4"
        >
          <div className="flex items-center gap-2 pl-0.5">
            <input checked={checked} className="size-4 cursor-pointer rounded border border-gray-300 text-gray-500 shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-default disabled:opacity-50" disabled={disabled} onChange={handleSelect} type="checkbox" />

            <ProjectLink backlink="projects" inNewTab={true} project={project}>
              <p className="inline-block text-base font-medium text-primary sm:text-lg">
                <span className="border-b-2 border-primary hover:text-primary-dark">{project.name}</span>
              </p>
            </ProjectLink>
          </div>

          <div>
            {project.locations.length > 1 && (
              <div className="flex">
                <ProjectLocation location={project.locations.find(({ default_location: defaultLocation }) => defaultLocation) ?? project.locations[0]} />

                {!showMultipleLocations && (
                  <button className="ml-2" onClick={() => setShowMultipleLocations(true)} type="button">
                    <ChevronDownIcon className="size-6 text-gray-500" />
                  </button>
                )}

                {showMultipleLocations && (
                  <button className="ml-2" onClick={() => setShowMultipleLocations(false)} type="button">
                    <ChevronUpIcon className="size-6 text-gray-500" />
                  </button>
                )}
              </div>
            )}

            <Transition
              as="div"
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={showMultipleLocations}
            >
              {project.locations.length > 1 && project.locations
                .filter(({ default_location: defaultLocation }) => !defaultLocation)
                .map((location, index) => (
                  <div className="flex" key={`multiple_location_${location.id}`}>
                    <ProjectLocation location={location} />
                  </div>
                ))}
            </Transition>

            {project.locations.length <= 1 && project.locations.map((location, index) => (
              <div className="flex" key={`single_location_${location.id}`}>
                <ProjectLocation location={location} />
              </div>
            ))}
          </div>

          <div className="flex flex-col items-start overflow-hidden">
            {[
              ...owners,
              ...builders,
              ...marketers
            ].map((role, index) => <ProjectCompany automated={project?.automated} key={index} role={role} />)}
          </div>
        </div>

        <div className="col-span-3 xl:col-span-2">
          {project?.show_image_public && project?.image_file_name && (
            <ProjectLink backlink="projects" inNewTab={true} project={project}>
              <div
                className={cn("relative w-64 xl:w-48 2xl:w-64 max-w-[calc(100vw-66px)] pb-2/3 rounded-lg overflow-hidden my-4", {
                  "h-40 xl:h-32 2xl:h-40": project?.image_landscape,
                  "h-64 xl:h-64 2xl:h-64": !project?.image_landscape
                })}
              >
                <img alt={project?.name} className={cn("absolute object-cover w-full h-full transition duration-500 transform", { "hover:scale-110": !project?.automated })} src={`https://immodeveloper.fra1.digitaloceanspaces.com/${project?.image_file_name}`} />
              </div>
            </ProjectLink>
          )}
        </div>

        <div className="col-span-6 text-sm sm:text-sm xl:col-span-4 2xl:col-span-3">
          <div className="flex items-start justify-start xl:justify-between">
            <div className="flex w-[min(100%,17.5rem)] items-center justify-between">
              <div
                className={cn(
                  "-ml-1 flex items-center text-sm gap-2",
                  {
                    "text-gray-500 font-medium": sort !== "finishYear",
                    "text-primary font-semibold": sort === "finishYear"
                  }
                )}
              >
                <CheckmarkCircleIcon />

                <p className="text-sm sm:text-base">
                  {
                    project?.automated
                      ? `Historisches Projekt${lastContractDate ? ` - ${lastContractDate}` : ""}`
                      : (completedDate ?? "nicht bekannt")
                  }
                </p>

              </div>

              {
                (project?.project_certificates && project?.project_certificates.length > 0)
                  ? <EsgIcon />
                  : null
              }
            </div>

            <div className="ml-4 mt-0.5 xl:ml-0">
              <WatchlistDropdown
                mutateWatchlists={mutateWatchlists}
                watchlists={watchlists}
                project={{
                  name: project.name,
                  slug: project.slug
                }}
              />
            </div>
          </div>

          <div className="mt-4 space-y-1">
            {(project?.timeline?.current_construction_phase && project.timeline.current_construction_phase !== "unknown") && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64 shrink-0">
                  <p className="font-semibold leading-4 text-gray-600">Bauphase:</p>
                </div>

                <div>
                  <p className="leading-4.5">{constructionPhaseLabels[project.timeline.current_construction_phase || ""]}</p>
                </div>
              </div>
            )}

            {(project?.timeline?.current_marketing_phase && project.timeline.current_marketing_phase !== "unknown") && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64 shrink-0">
                  <p className="font-semibold leading-4 text-gray-600">Vermarktungsphase:</p>
                </div>

                <div>
                  <p className="leading-4">{marketingPhaseLabels[project.timeline.current_marketing_phase || ""]}</p>
                </div>
              </div>
            )}
          </div>

          <div className="mt-5">

            {(project?.transaction_filter && liegenschaftLabels[project?.transaction_filter]) && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className="font-semibold text-gray-600">Liegenschaftsankauf: </p>
                </div>

                <div>
                  <p>{liegenschaftLabels[project.transaction_filter]}</p>
                </div>
              </div>
            )}

            <div className="flex flex-col sm:flex-row">
              <div className="w-64">
                <p className="font-semibold text-gray-600">Rechtsform:</p>
              </div>

              <div>
                {displayRechtsform(project)}
              </div>
            </div>

            {
              project.count_wohnungen !== 0 && (
                <div className="flex flex-col sm:flex-row">
                  <div className="w-64">
                    <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "countWohnungen" })}>
                      {project.automated ? "Erfasste Transaktionen" : "Gesamtanzahl Wohneinheiten"}:
                    </p>
                  </div>

                  <div>
                    <p className={cn("", { "text-primary font-semibold": currentSortValue === "countWohnungen" })}>
                      {project.count_wohnungen}
                    </p>
                  </div>
                </div>
              )
            }
          </div>

          {
            (project?.funding?.not_funded || project?.funding?.normal_funded) && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600")}>
                    Förderung:
                  </p>
                </div>

                <div>
                  {project.funding.not_funded && project.funding.normal_funded &&
                    <p>gemischt</p>}

                  {!project.funding.not_funded && project.funding.normal_funded &&
                    <p>gefördert</p>}

                  {project.funding.not_funded && !project.funding.normal_funded &&
                    <p>freifinanziert</p>}
                </div>
              </div>
            )
          }

          <div className="mt-5">
            {(project?.gka && project?.show_gka) && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "gka" })}>
                    Grundkostenanteil:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "gka" })}>
                    {formatEuro(project?.gka)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_offer_price_eigennutzer && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgOfferPriceEigennutzer" })}>
                    {priceLabels.averageOfferPriceNormal}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgOfferPriceEigennutzer" })}>
                    {formatEuro(project?.avg_offer_price_eigennutzer)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_offer_price_investor && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgOfferPriceEigennutzer" })}>
                    {priceLabels.averageOfferPriceInvestorNet}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgOfferPriceEigennutzer" })}>
                    {formatEuro(project?.avg_offer_price_investor)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_miete && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgMiete" })}>
                    {priceLabels.averageRentNet}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgMiete" })}>
                    {formatEuro(project?.avg_miete)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_rent_bk && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgMiete" })}>
                    {priceLabels.averageRentTotal}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgMiete" })}>
                    {formatEuro(project?.avg_rent_bk)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_sale_price_eigennutzer && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgSalePriceEigennutzer" })}>
                    {priceLabels.averageSalePriceNormal}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgSalePriceEigennutzer" })}>
                    {formatEuro(project?.avg_sale_price_eigennutzer)}/m²
                  </p>
                </div>
              </div>
            )}

            {project?.avg_sale_price_investor && (
              <div className="flex flex-col sm:flex-row">
                <div className="w-64">
                  <p className={cn("font-semibold text-gray-600", { "text-primary": currentSortValue === "avgSalePriceEigennutzer" })}>
                    {priceLabels.averageSalePriceInvestorNet}:
                  </p>
                </div>

                <div>
                  <p className={cn("", { "text-primary font-semibold": currentSortValue === "avgSalePriceEigennutzer" })}>
                    {formatEuro(project?.avg_sale_price_investor)}/m²
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectListItem;
