import useSignedSource from "~/src/hooks/use-signed-source";

/**
 *
 * @param root0 - The root object
 * @param root0.document - The root object
 * @example
 */
export default function SignedUnitDocument({ document }) {
  const { src } = useSignedSource(document.file_name);

  return (
    <a className="text-sm font-semibold text-gray-500 underline hover:text-gray-400 focus:outline-none" href={src || ""} rel="noreferrer" target="_blank">
      {document.public_name}
    </a>
  );
}
