/**
 *
 * @example
 */
export default function DetailSkeleton() {
  return (
    <div className="mt-8 space-y-8 rounded border border-gray-200 bg-white py-2">

      {Array.from({ length: 3 }).map((e, index) => (
        <div className="animate-pulse space-y-8 px-3 py-1.5 sm:px-6 sm:py-4" key={`block_0_${index}`}>
          <div className="grid grid-cols-3 space-x-4">
            <div className="col-span-1 h-12 rounded bg-gray-300" />

            <div className="col-span-1 h-12 rounded bg-gray-300" />

            <div className="col-span-1 h-12 rounded bg-gray-300" />
          </div>

          <div className="grid grid-cols-3 space-x-4">
            <div className="col-span-1 h-12 rounded bg-gray-300" />

            <div className="col-span-1 h-12 rounded bg-gray-300" />

            <div className="col-span-1 h-12 rounded bg-gray-300" />
          </div>
        </div>
      ))}

    </div>
  );
}
