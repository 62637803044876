import { Suspense } from "react";
import {
  Route,
  Switch
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import InsolvenciesPage from "~/src/pages/insolvencies";
import KartePage from "~/src/pages/karte";
import ListePage from "~/src/pages/liste";
import Project from "~/src/pages/projects/[id]";
import Purchases from "~/src/pages/purchases";
import ShareDealsPage from "~/src/pages/share-deals";
import StatisticsPage from "~/src/pages/statistics";
import Watchlists from "~/src/pages/watchlists";
import Watchlist from "~/src/pages/watchlists/[id]";

import useMatomo from "~/src/hooks/use-matomo";

import ProtectedRoute from "~/src/ui/protected-route";
import ScrollTop from "~/src/ui/scroll-top";

import DefaultErrorPage from "./pages/errors/default-error";
import NotAuthorizedPage from "./pages/errors/not-authorized";
import NotFoundPage from "./pages/errors/not-found";
import PrintListPage from "./pages/print/list";
import PrintOverviewPage from "./pages/print/overview";
import PrintStatisticsPage from "./pages/print/statistics";
import ProtectedInternalRoute from "./ui/protected-internal-route";

/**
 *
 * @example
 */
export default function AppRouter() {
  useMatomo();

  return (
    <Switch>
      <Route path="/">
        <ScrollTop />

        <ToastContainer closeOnClick />

        <Switch>
          <ProtectedRoute exact path="/">

            <KartePage />

          </ProtectedRoute>

          <ProtectedRoute path="/karte">

            <KartePage />

          </ProtectedRoute>

          <ProtectedRoute path="/liste">

            <ListePage />

          </ProtectedRoute>

          <ProtectedRoute path="/statistik">

            <StatisticsPage />

          </ProtectedRoute>

          <ProtectedInternalRoute exact path="/print/overview">

            <PrintOverviewPage />

          </ProtectedInternalRoute>

          <ProtectedInternalRoute exact path="/print/list">

            <PrintListPage />

          </ProtectedInternalRoute>

          <ProtectedInternalRoute exact path="/print/statistics">

            <PrintStatisticsPage />

          </ProtectedInternalRoute>

          <ProtectedRoute exact path="/transaktionen">

            <Purchases />

          </ProtectedRoute>

          <ProtectedRoute exact path="/share-deals">

            <ShareDealsPage />

          </ProtectedRoute>

          <ProtectedRoute exact path="/insolvenzen">

            <InsolvenciesPage />

          </ProtectedRoute>

          <ProtectedRoute exact path="/merklisten">

            <Watchlists />

          </ProtectedRoute>

          <ProtectedRoute exact path="/merklisten/:id">

            <Watchlist />

          </ProtectedRoute>

          <ProtectedRoute path="/projekte/:id">

            <Project />

          </ProtectedRoute>

          <Route path="/not-authorized">
            <NotAuthorizedPage />
          </Route>

          <Route path="/not-found">
            <NotFoundPage />
          </Route>

          <Route path="*">
            <DefaultErrorPage />
          </Route>
        </Switch>
      </Route>
    </Switch>
  );
}
