import animateScrollTo from "animated-scroll-to";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  DelimitedNumericArrayParam, NumberParam, StringParam, useQueryParams, withDefault
} from "use-query-params";

import { pluralize } from "~/src/modules/formatters";
import { updateQueryFromTemp } from "~/src/modules/query-helper";

import ShareDealFilterTags from "~/src/features/filter-tags/share-deal-filter-tags";
import Pagination from "~/src/features/pagination";
import RegionFilterModal from "~/src/features/region-filter-modal";
import ShareDealsPropertyList from "~/src/features/share-deals-property-list";
import ShareDealsTransactionList from "~/src/features/share-deals-transaction-list";
import SortPurchasesDirection from "~/src/features/sort-purchases-direction";
import SortShareDealPropertiesDropdown from "~/src/features/sort-share-deal-properties-dropdown";
import SortShareDealTransactionsDropdown from "~/src/features/sort-share-deal-transactions-dropdown";

import useAccountRegions from "~/src/hooks/use-account-regions";
import useShareDeals from "~/src/hooks/use-share-deals";

import FilterButton from "~/src/ui/buttons/filter-button";
import DateInput from "~/src/ui/inputs/date-month-input";
import Page from "~/src/ui/page";
import ShareDealCategorySelect from "~/src/ui/selects/share-deal-category-select";
import ShareDealCompanySelect from "~/src/ui/selects/share-deal-company-select";

/**
 *
 * @example
 */
export default function ShareDealsPage() {
  const [query, setQuery] = useQueryParams({
    cat: StringParam,
    catId: NumberParam,
    company: StringParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    direction: StringParam,
    owner: StringParam,
    page: NumberParam,
    regions: withDefault(DelimitedNumericArrayParam, []),
    sortBy: StringParam,
    sortByTransaction: StringParam,
    type: StringParam
  });

  const [temporaryQuery, setTemporaryQuery] = useState({});

  const { account_regions } = useAccountRegions();
  const {
    isError, isLoading, pagination, shareDeals, sortDirection
  } = useShareDeals({ query });

  const [regionFilterModal, setRegionFilterModal] = useState(false);

  if (isError) {
    <Redirect to="/error" />;
  }

  /**
   *
   * @param newPage
   * @example
   */
  function handlePageChange(newPage) {
    setQuery({ page: newPage });
    setTimeout(() => {
      animateScrollTo(0, {
        speed: 120
      });
    }, 120);
  }

  /**
   *
   * @example
   */
  function handleClearFilters() {
    setQuery({
      ...query,
      cat: undefined,
      catId: undefined,
      company: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      direction: undefined,
      owner: undefined,
      page: 1,
      regions: undefined
    });
  }

  /**
   *
   * @param value
   * @example
   */
  function handleSubmit(value) {
    setQuery({
      ...value,
      page: 1
    });
  }

  /**
   *
   * @param key
   * @param value
   * @example
   */
  function updateTemporaryQuery(key, value) {
    const q = {
      ...temporaryQuery,
      [key]: value
    };

    setTemporaryQuery(q);
  }

  /**
   *
   * @example
   */
  function handleSubmitQuery() {
    const q = updateQueryFromTemp(query, temporaryQuery);

    setQuery(q);
    setTemporaryQuery({});
  }

  /**
   *
   * @example
   */
  function cancelRegionFilter() {
    const q = {
      ...temporaryQuery
    };

    delete q.regions;
    setTemporaryQuery(q);
  }

  return (
    <Page title="Share Deals">

      <RegionFilterModal account_regions={account_regions} active={regionFilterModal} cancelRegionFilter={cancelRegionFilter} hide={() => setRegionFilterModal(false)} queryKey="regions" queryRegion={query.regions} submit={handleSubmitQuery} tempQuery={temporaryQuery} update={updateTemporaryQuery} />

      <div className="mx-auto px-4 xl:container lg:px-16">
        <h2 className="mt-8 text-3xl font-medium text-gray-700">Share Deals</h2>
      </div>

      <div className="mx-auto mt-1 px-4 py-5 xl:container lg:px-16">

        <div className="mt-4 items-center sm:flex">

          <p className="font-semibold text-gray-700">Aufgelistet nach:</p>

          <div className="ml-8 mt-2 flex items-center sm:mt-0">
            <input
              checked={query?.type !== "liegenschaft"}
              className="size-5 cursor-pointer border-gray-300 text-primary focus:ring-0"
              id="show-companies"
              name="show-companies"
              onChange={() => handleSubmit({ type: "transaktion" })}
              type="radio"
            />

            <label className="ml-3 block cursor-pointer text-gray-700" htmlFor="show-companies">
              Personen- und Anteilsänderungen
            </label>
          </div>

          <div className="ml-8 flex items-center">
            <input
              checked={query?.type === "liegenschaft"}
              className="size-5 cursor-pointer border-gray-300 text-primary focus:ring-0"
              id="show-liegenschaften"
              name="show-liegenschaften"
              onChange={() => handleSubmit({ type: "liegenschaft" })}
              type="radio"
            />

            <label className="ml-3 block cursor-pointer text-gray-700" htmlFor="show-liegenschaften">
              Liegenschaften
            </label>
          </div>

        </div>

        <div className="mt-10 w-full items-center justify-between lg:flex">

          <div>
            <div className="items-center lg:flex">
              <div className="mt-2 w-full sm:mt-0 lg:w-80">

                <ShareDealCategorySelect
                  onUpdate={(id, label) => setQuery({
                    cat: label,
                    catId: id
                  })}
                />
              </div>

              <div className="mt-2 w-full lg:ml-2 lg:mt-0 lg:w-140">
                <ShareDealCompanySelect onUpdate={(searchTerm) => handleSubmit({ company: searchTerm })} />
              </div>

            </div>

            <div className="mt-5 flex h-full items-center gap-x-3">
              {query?.type === "liegenschaft" &&
                <FilterButton onClick={() => setRegionFilterModal(true)} title="Regionen" />}

              <DateInput hasOtherDate={query?.dateTo} maxDate={query?.dateTo ? new Date(query.dateTo) : new Date()} name="dateFrom" onChange={handleSubmit} placeholder="Datum von" />

              <DateInput hasOtherDate={query?.dateFrom} maxDate={new Date()} minDate={query?.dateFrom ? new Date(query.dateFrom) : undefined} name="dateTo" onChange={handleSubmit} placeholder="Datum bis" />
            </div>
          </div>

        </div>
      </div>

      <div className="mx-auto mt-5 px-4 xl:container lg:px-16">

        <ShareDealFilterTags handleClearFilters={handleClearFilters} onSubmit={handleSubmit} query={query} />

        <div className="mt-4 flex flex-col items-center justify-between lg:flex-row lg:items-center">
          {!isLoading && (
            <>
              {query?.type !== "liegenschaft" &&
                <h3 className="mb-3 text-center text-xl font-light text-gray-800 lg:mb-0 lg:text-left 2xl:text-2xl">{pagination?.totalRecords?.toLocaleString("de")} {pluralize("Personen- und Anteilsänderung", "Personen- und Anteilsänderungen", "Personen- und Anteilsänderungen", pagination?.totalRecords)} gefiltert</h3>}

              {query?.type === "liegenschaft" &&
                <h3 className="mb-3 text-center text-xl font-light text-gray-800 lg:mb-0 lg:text-left 2xl:text-2xl">{pagination?.totalRecords?.toLocaleString("de")} {pluralize("Liegenschaft", "Liegenschaften", "Liegenschaften", pagination?.totalRecords)} gefiltert</h3>}
            </>
          )}

          {!pagination?.totalRecords && isLoading &&
            <div className="h-5 w-64 animate-pulse rounded bg-gray-300" />}

          {!isLoading && (
            <div className="items-center justify-end lg:flex">
              {query?.type === "liegenschaft" &&
                <SortShareDealPropertiesDropdown onSubmit={handleSubmit} sortBy={query.sortBy} />}

              {query?.type !== "liegenschaft" &&
                <SortShareDealTransactionsDropdown onSubmit={handleSubmit} sortByTransaction={query.sortByTransaction} />}

              <div className="mb-2 ml-6 mr-1 lg:mb-0">
                <Pagination onPageChange={handlePageChange} page={query.page} pagination={pagination} />
              </div>

              <div className="flex justify-center lg:block">
                <SortPurchasesDirection direction={sortDirection} isLoading={isLoading} onSubmit={handleSubmit} />
              </div>
            </div>
          )}

          {isLoading &&
            <div className="mb-1 mt-4 h-5 w-96 animate-pulse rounded bg-gray-300" />}
        </div>

      </div>

      <div className="mx-auto mt-2 px-4 pb-5 xl:container lg:px-16">

        {query?.type !== "liegenschaft" &&
          <ShareDealsTransactionList isLoading={isLoading} query={query} transactions={shareDeals} />}

        {query?.type === "liegenschaft" &&
          <ShareDealsPropertyList isLoading={isLoading} properties={shareDeals} query={query} />}

        <Pagination onPageChange={handlePageChange} page={query.page} pagination={pagination} />

      </div>

    </Page>
  );
}
