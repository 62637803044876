import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { Link, useLocation } from "react-router-dom";

/**
 *
 * @example
 */
export default function BackLink() {
  const { state } = useLocation();

  const linkOptions = {
    pathname: state?.pathname || "/karte",
    search: state?.backlink || state?.search
  };

  return (
    <Link
      className="flex items-center gap-2 text-sm font-medium text-gray-200 hover:text-white focus:outline-none"
      to={linkOptions}
    >
      <ArrowLongLeftIcon className="size-5" />

      <span className="whitespace-nowrap">
        zurück zur Übersicht
      </span>
    </Link>
  );
}
