// import useAuth from "~/src/hooks/use-auth";
// import API from "~/src/modules/api"

/**
 *
 * @example
 */
export default function LogoutButton() {
  return (
    <div>
      <a href="https://immounited.com" rel="noopener noreferrer">
        zurück zu IMMOunited.com
      </a>
    </div>
  );
  // const { user } = useAuth()

  // const handleLogout = async () => {
  //   const res = await API.post("/auth/logout")
  //   window.location.replace(res.data.payload.logoutUrl)
  // }

  // return (
  //   <div>
  //     <button type="button" onClick={handleLogout} className="text-lg font-light hover:underline focus:outline-none">
  //       {user?.username ? `Logout: ${user?.username}` : "Logout"}
  //     </button>
  //   </div>
  // )
}
