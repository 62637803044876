import animateScrollTo from "animated-scroll-to";
import { useEffect, useState } from "react";
import {
  Link, Redirect, useParams
} from "react-router-dom";
import {
  NumberParam, StringParam, useQueryParams
} from "use-query-params";

import { calculateStats } from "~/src/modules/stats-helper";

import EmptyProjectBucket from "~/src/features/empty-project-bucket";
import Pagination from "~/src/features/pagination";
import MapSlide from "~/src/features/slides/map-slide";
import SortPurchasesDirection from "~/src/features/sort-purchases-direction";
import SortWatchlistProjectsDropdown from "~/src/features/sort-watchlist-projects-dropdown";
import StatsSlide from "~/src/features/stats-slide";
import ToggleViewButton from "~/src/features/toggle-view-button";
import ProjectList from "~/src/features/watchlist-project-list";

import useWatchlist from "~/src/hooks/use-watchlist";

import BackIcon from "~/src/ui/icons/back-icon";
import Page from "~/src/ui/page";

/**
 *
 * @example
 */
export default function WatchlistPage() {
  const { id } = useParams();
  const [query, setQuery] = useQueryParams({
    direction: StringParam,
    page: NumberParam,
    sortBy: StringParam
  });
  const {
    error, isLoading, mutate: mutateWatchlist, pagination, projects, sortDirection, watchlist
  } = useWatchlist({
    id,
    query
  });
  const [stats, setStats] = useState({});
  const [statsSlide, setStatsSlide] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [singleMapSlide, setSingleMapSlide] = useState(false);
  const [singleMapProject, setSingleMapProject] = useState(null);

  if (error) {
    <Redirect to="/error" />;
  }

  /**
   *
   * @param newPage
   * @example
   */
  function handlePageChange(newPage) {
    setQuery({ page: newPage });
    setTimeout(() => {
      animateScrollTo(0, {
        speed: 50
      });
    }, 120);
  }

  /**
   *
   * @param value
   * @example
   */
  function handleSubmit(value) {
    setQuery(value);
  }

  /**
   *
   * @param project
   * @param showLocationNumber
   * @example
   */
  function handleSingleMapProject(project, showLocationNumber) {
    setSingleMapProject({
      project,
      showLocationNumber
    });
    setSingleMapSlide(true);
  }

  const stringProjects = JSON.stringify(projects);

  useEffect(() => {
    setStats(calculateStats(projects));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringProjects]);

  return (
    <Page title="Merkliste">
      <MapSlide hide={() => setSingleMapSlide(false)} projectSlugs={singleMapProject?.project?.slug ? [singleMapProject.project.slug] : []} show={singleMapSlide} />

      <MapSlide hide={() => setShowMap(false)} projectSlugs={projects?.map(({ slug }) => slug)} show={showMap} />

      <StatsSlide active={statsSlide} hide={() => setStatsSlide(false)} stats={stats} />

      <div className="mx-auto mt-8 flex items-start justify-between px-4 xl:container lg:px-16">
        <div className="w-full">

          <div>
            <Link className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700" to="/merklisten">
              <BackIcon />
              Alle Merklisten
            </Link>
          </div>

          <div className="mt-2 flex items-start justify-between">
            {!isLoading && (
              <div className="truncate">
                <h2 className="truncate text-3xl font-medium text-gray-700">{watchlist?.name}</h2>

                <p className="text-lg font-light text-gray-600">{pagination?.totalRecords} / 200 Projekte</p>
              </div>
            )}

            {isLoading && (
              <div className="">
                <p className="h-8 w-56 rounded bg-gray-300" />

                <p className="mt-3 h-4 w-32 rounded bg-gray-300" />
              </div>
            )}

            <div className="ml-4">
              <ToggleViewButton setShowMap={setShowMap} setShowStats={setStatsSlide} />
            </div>
          </div>
        </div>

      </div>

      <div className="mx-auto mt-8 px-4 xl:container">

        {projects?.length === 0 && (
          <div className="mx-auto w-2/3">
            <EmptyProjectBucket />
          </div>
        )}

        {!isLoading && projects?.length > 0 && (
          <div className="flex items-center justify-end lg:mx-12">
            <SortWatchlistProjectsDropdown onSubmit={handleSubmit} sortBy={query.sortBy} />

            <div className="ml-6 mr-1">
              <Pagination onPageChange={handlePageChange} page={query.page} pagination={pagination} />
            </div>

            <SortPurchasesDirection direction={sortDirection} isLoading={isLoading} onSubmit={handleSubmit} />
          </div>
        )}

        {isLoading && (
          <div className="mb-1 mt-12 flex justify-end lg:mx-12 ">
            <div className="h-5 w-56 animate-pulse rounded bg-gray-300" />
          </div>
        )}

        <ProjectList handleSingleMapProject={handleSingleMapProject} isLoading={isLoading} mutateWatchlist={mutateWatchlist} projects={projects} watchlist={watchlist} />

      </div>

    </Page>
  );
}
