import { Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

/**
 *
 * @param root0 - The root object
 * @param root0.watchlist - The root object
 * @param root0.nameFilter - The root object
 * @param root0.handleEdit - The root object
 * @param root0.handleDelete - The root object
 * @example
 */
export default function WatchlistItem({
  handleDelete, handleEdit, nameFilter, watchlist
}) {
  return (

    <Transition
      as="div"
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={watchlist.name.toLowerCase().includes(nameFilter.toLowerCase())}
    >
      <div className="rounded-sm border border-gray-200 bg-white">

        <div className="w-full px-5 py-3">
          <h3 className="truncate text-xl font-medium text-gray-800">
            <Link className="hover:underline" to={`/merklisten/${watchlist.uuid}`}>{watchlist.name}</Link>
          </h3>

          <div className="mt-1 flex items-center justify-between">
            <p className="text-lg font-light text-gray-800"><span className=" text-gray-600">{watchlist.numberOfProjects}</span> / 200 Projekte</p>

            {watchlist.name !== "Standardliste" && (
              <div className="flex items-center">
                <button className="text-gray-500 hover:text-gray-600 focus:outline-none" onClick={() => handleEdit(watchlist)} type="button">
                  <PencilIcon className="size-5" />
                </button>

                <button className="text-gray-500 hover:text-gray-600 focus:outline-none" onClick={() => handleDelete(watchlist)} type="button">
                  <TrashIcon className="ml-3.5 size-5" />
                </button>
              </div>
            )}

          </div>
        </div>
      </div>
    </Transition>
  );
}
