import cn from "classnames";
import { components } from "react-select";
import Select from "react-select/async";
import { debounce, throttle } from "throttle-debounce";

import API from "~/src/modules/api";

import MarkerIcon from "~/src/ui/icons/marker-icon";

/**
 *
 * @param props - The root object
 * @param props.title - The root object
 * @param props.onSelect - The root object
 * @param props.className - The root object
 * @param props.isMobile - The root object
 * @example
 */
export default function LocationSearchSelect({
  className,
  isMobile = false,
  onSelect,
  title
}) {
  const handleSelect = (selected) => {
    if (selected) {
      const {
        label,
        position
      } = selected;

      console.log({
        selected
      });

      onSelect({
        coords: { ...position },
        title: label
      });
    }
    else {
      onSelect(null);
    }
  };

  return (
    <Select
      blurInputOnSelect
      isClearable
      cacheOptions={false}
      className={cn("react-select", className)}
      instanceId="location-search"
      loadOptions={handleLoadOptions}
      name="location-search"
      noOptionsMessage={noOptionsMessage}
      onChange={handleSelect}
      placeholder="Adresse anzeigen"
      styles={customStyles(isMobile)}
      components={{
        Control,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input
      }}
      value={title
        ? {
          label: title,
          value: 1
        }
        : ""}
    />
  );
}

/**
 *
 * @param props0 - The root object
 * @param props0.children - The root object
 * @example
 */
function Control({ children, ...rest }) {
  return (
    <components.Control {...rest} >
      <MarkerIcon className="size-[15px] shrink-0 text-primary-lighter" /> {children}
    </components.Control>
  );
}

const loadOptions = async (inputValue, callback) => {
  try {
    const res = await API.post("/search/autosuggest-locations", {
      input: inputValue,
      type: "map"
    });

    const options = res.data.payload.locations.items.map((location) => {
      const {
        displayValue,
        filter: {
          position: {
            lat, lng
          } = {
            lat: null,
            lng: null
          },
          ...locationIds
        },
        type
      } = location;

      return ({
        label: displayValue,
        locationIds,
        locationType: type,
        ...((lat && lng)
          ? {
            position: {
              lat,
              lng
            }
          }
          : {}
        )
      });
    });

    callback(options);
  }
  catch (error) {
    console.log("error", error);
  }
};

const loadOptionsDebounced = debounce(500, loadOptions);
const loadOptionsThrottled = throttle(500, loadOptions);

const handleLoadOptions = (inputValue) => new Promise((resolve, reject) => {
  if (inputValue.length < 4) {
    loadOptionsThrottled(inputValue, (options) => {
      resolve(options);
    });
  }
  else {
    loadOptionsDebounced(inputValue, (options) => {
      resolve(options);
    });
  }
});

const noOptionsMessage = (data) => {
  if (data.inputValue.length > 0) {
    return "Keine Vorschläge gefunden...";
  }

  return "Adresse suchen...";
};

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete="off" />;

const customStyles = (isMobile) => ({
  control: (styles, state) => ({
    ...styles,
    minHeight: "unset",
    "&:hover": {
      borderColor: "#cfcfcf"
    },
    borderColor: state.isFocused ? "#cfcfcf" : "#e5e5e5",
    borderRadius: "0.25rem",
    boxShadow: "none",
    cursor: "pointer",
    fontSize: "inherit",
    fontWeight: 300,
    gap: "0.3rem",
    height: isMobile ? "24px" : "30px", // getControlHeight(),
    padding: "0.25rem 0.5rem"
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: "#9ca3af",
    fontSize: "inherit",
    fontWeight: 300
  }),

  singleValue: (base) => ({
    ...base,
    background: "#D1D5DB",
    borderRadius: "0.25rem",
    color: "#374151",
    fontSize: "inherit",
    fontWeight: 300,
    margin: "0px",
    padding: "0.1rem 0.25rem"
  }),

  indicatorContainer: (base) => ({
    ...base,
    padding: "0px"
  }),
  indicatorsContainer: (base) => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    padding: "0px"
  }),
  input: (base) => ({
    ...base,
    fontSize: "inherit",
    fontWeight: 300,
    margin: "0px",
    padding: "0px"
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: "inherit",
    fontWeight: 300,
    lineHeight: "100%",
    margin: "0px",
    padding: "0px"
  })
});
