import { useMemo, useState } from "react";

import DetailStatsTools from "~/src/lib/detail-stats-tools";

import { priceLabels } from "~/src/modules/labels.js";

import useMediaQuery from "~/src/hooks/use-media-query";

import ChartLegend from "~/src/ui/charts/legend";
import LineChart from "~/src/ui/charts/line-chart";
import PriceSelect from "~/src/ui/selects/price-select";

import EmptyChartContainer from "../empty-chart-container";
import { SquareMeterPriceByAreaSaleChartForPrint } from "../square-meter-price-by-area-sale/for-print";

import { generateLineData } from "./tools";

/**
 *
 * @param props - The root object
 * @param props.statistic - The root object
 * @param props.isLoading - The root object
 * @param props.isError - The root object
 * @example
 */
export default function SquareMeterPriceByAreaSaleChart({ statistic }) {
  const isPrint = useMediaQuery("print");

  const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);

  const allGraphLabels = Object.keys(statistic);

  const labelsOfNonemptyGraphs = DetailStatsTools
    .getVariablesWithData(statistic);

  const activeGraphLabel = selectedGraphLabel ?? labelsOfNonemptyGraphs?.[0];

  const lineData = useMemo(
    () => (activeGraphLabel
      ? generateLineData(activeGraphLabel, statistic)
      : null),
    [statistic, activeGraphLabel]
  );

  if (isPrint) {
    return (
      <SquareMeterPriceByAreaSaleChartForPrint
        graphLabels={labelsOfNonemptyGraphs}
        statistic={statistic}
      />
    );
  }

  if (!labelsOfNonemptyGraphs?.[0] || !lineData) {
    return (
      <EmptyChartContainer
        allGraphLabels={allGraphLabels}
        chartLabel={`${priceLabels.salePricePerSquareMeter} nach Fläche`}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4 rounded border border-gray-200 bg-white p-2 shadow-sm lg:p-4">
      <div className="flex flex-col justify-between gap-2 lg:flex-row">
        <div className="flex items-center gap-2">
          <h2 className="text-md font-medium text-gray-900">{priceLabels.salePricePerSquareMeter} nach Fläche</h2>
        </div>

        <PriceSelect
          buttonClassName="w-80"
          className="w-80 lg:self-end"
          enabledOptions={labelsOfNonemptyGraphs}
          onChange={setSelectedGraphLabel}
          options={allGraphLabels}
          value={activeGraphLabel}
        />
      </div>

      <div className="relative h-[450px] w-full">
        <div className="absolute h-[450px] w-full">
          <LineChart
            withCategories
            data={lineData.chartData}
            x={{
              min: 0,
              max: 200,
              type: "squareMeters"
            }}
            y={{
              min: lineData.chartData[0].min,
              max: lineData.chartData[0].max
            }}
          />
        </div>
      </div>

      <div className="w-full">
        <ChartLegend simple data={lineData.legendData} />
      </div>
    </div>
  );
}
