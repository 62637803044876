import cn from "classnames";
import {
  Link,
  useLocation,
  useRouteMatch
} from "react-router-dom";

/**
 * Tab elements, which allow to switch between different routes.
 * Depending on the choosen route, the tab element associated
 * to the current route receives a special styling.
 *
 * @param {object} props - Object to extract the params lable, to, activeOnlyWhenExact from.
 * @param {string} props.label - Naming for the Link tab.
 * @param {path} props.to - Link, where the Link tab routes to.
 * @param {boolean} props.activeOnlyWhenExact - Check if route has exact match if true, otherwise partial check.
 * @returns {React.Element} The styled tab link.
 * @example
 */
export default function TabLink({
  activeOnlyWhenExact = true,
  label,
  to
}) {
  const { state } = useLocation();
  const match = useRouteMatch({
    exact: activeOnlyWhenExact,
    path: to
  });

  const linkOptions = {
    pathname: to,
    state
  };

  return (
    <Link
      replace
      to={linkOptions}
      className={cn(
        "px-3 py-2 text-center flex justify-center items-center rounded  font-medium text-sm",
        {
          "bg-primary text-white": match,
          "bg-secondary-dark text-secondary-darkest hover:bg-secondary-darker": !match
        }
      )}
    >
      {label}
    </Link>
  );
}
