import {
  Dialog, DialogBackdrop, DialogPanel, TransitionChild
} from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Tooltip } from "react-tooltip";

import { formatAreaInt, formatDate } from "~/src/modules/formatters";
import { propertyTypeLabels } from "~/src/modules/labels";

import FeedbackModal from "~/src/features/feedback-modal";
import SlidePropertyCard from "~/src/features/slide-property-card";

import CloseIcon from "~/src/ui/icons/close-icon";

const propertyTypeCount = 9;

const renderPropertyTypes = (property) => {
  let hasValue = false;

  const propertyTypes = Array.from(
    { length: propertyTypeCount },
    (empty, index) => {
      const availableCount = property[`property_type_${index}_count`];

      if (availableCount) {
        hasValue = true;

        return (
          <p key={`property_type_${index}`}>{availableCount} x {propertyTypeLabels[`type_${index}`]}</p>
        );
      }

      return null;
    }
  );

  return hasValue ? propertyTypes : null;
};

/**
 *
 * @param {object} props - The root object
 * @param {boolean} props.active - The root object
 * @param {Function} props.hide - The root object
 * @param {object} props.property - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function InsolvencyPropertySlide({
  active, hide, property
}) {
  const [showFeedback, setShowFeedback] = useState(false);

  const handleHide = () => {
    hide();
  };

  return (
    <Dialog
      className="fixed inset-0 z-[150] overflow-hidden"
      onClose={handleHide}
      open={active}
    >
      <FeedbackModal
        close={() => setShowFeedback(false)}
        entity_id={property?.id}
        feedback_type="insolvency-property"
        feedback_type_label={property?.insolvency?.record_number}
        show={showFeedback}
      />

      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-4xl transition duration-200 ease-in-out data-[closed]:translate-x-full sm:duration-200"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-200 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    className="rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-0 focus:ring-white"
                    onClick={handleHide}
                  >
                    <span className="sr-only">Schließen</span>

                    <CloseIcon aria-hidden="true" className="size-6" />
                  </button>
                </div>
              </TransitionChild>

              <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">

                <div className="flex h-16 shrink-0 items-center justify-between bg-primary px-4 sm:px-12">
                  <h2 className="text-lg font-medium text-white" id="slide-over-heading">
                    Details zur Liegenschaft
                  </h2>
                </div>

                <div className="mt-4 flex-1">

                  <dl className="px-4 sm:px-8">

                    <div className="flex items-center justify-between px-4">
                      <h3 className="text-2xl font-semibold text-gray-700">
                        {property.property_street} {property.property_house_number}

                        <br />

                        {property.property_state}

                        ,
                        {property.property_zipcode}

                        {" "}

                        {property.property_city?.replace(",", ", ")}
                      </h3>

                      <button
                        className="rounded border border-primary bg-primary px-3 py-1 text-sm text-white hover:bg-primary-lighter focus:outline-none"
                        onClick={() => setShowFeedback(true)}
                        type="button"
                      >
                        Feedback
                      </button>
                    </div>

                    <div className="mt-8">

                      <div className="mx-4 px-4 py-2">
                        <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">KG</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{property.kgnr}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">EZ</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{property.ez}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">EZ Fläche</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{formatAreaInt(property.ez_area)}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">GST-Nr.</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{property.gst_numbers?.join("; ")}</dd>
                        </div>

                        <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                          <dt className="font-semibold text-gray-800">Gebäudeart</dt>

                          <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">
                            {renderPropertyTypes(property) || "-"}
                          </dd>
                        </div>
                      </div>

                      <div className="mb-4 mt-10">

                        <div className="flex items-end justify-between">
                          <div className="ml-4 flex text-xl">
                            <p>Liegenschaftseigentümer/Schuldner</p>

                            {/* <div>
                              <div
                                data-tooltip-content="Stichtagsdaten vom 01.04.2021"
                                data-tooltip-id="tooltip-insolvency-property-owner"
                                tabIndex="-1"
                              >
                                <InformationCircleIcon
                                  className="ml-2 mt-0.5 size-5 text-gray-600 hover:text-gray-700 focus:outline-none"
                                  tabIndex="-1"
                                />
                              </div>

                              <Tooltip
                                className="z-50"
                                delayShow={500}
                                id="tooltip-insolvency-property-owner"
                                place="bottom"
                              />
                            </div> */}
                          </div>
                        </div>

                        <div className="mx-4 mt-3 px-4 py-2">
                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Firmenname</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{property?.owner_name}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">FB-Nr.</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{property?.owner_fbnr}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Adresse</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-3 sm:mt-0">{property?.owner_address}</dd>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 mt-10">
                        <p className="ml-4 text-xl">Details zum Insolvenzverfahren</p>

                        <div className="mx-4 mt-2 px-4 py-2">
                          <div className="sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Veröffentlichung</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">{formatDate({ date: property.insolvency?.published_date })}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Letzte Änderung</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">{formatDate({ date: property.insolvency?.last_change_date })}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Verfahrensart</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">{property.insolvency?.category?.name}</dd>
                          </div>

                          <div className="mt-0.5 sm:grid sm:grid-cols-4 sm:gap-x-4">
                            <dt className="font-semibold text-gray-800">Aktenzeichen</dt>

                            <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
                              <a
                                className="inline-flex items-center hover:underline"
                                href={property.insolvency?.url}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <span>{property.insolvency?.record_number}</span>

                                <ArrowTopRightOnSquareIcon className="-mr-1 ml-1 size-5 text-primary" />
                              </a>
                            </dd>
                          </div>
                        </div>
                      </div>

                      <div className="mt-16">

                        <div className="mt-3 flex items-end justify-between px-4">
                          <p className="text-xl">Alle Liegenschaften des Schuldners ({property.insolvency?.properties?.length})</p>

                          {/* <p className="text-sm leading-none">Stichtagsdaten vom 01.04.2021</p> */}
                        </div>

                        <div className="mt-2.5 space-y-5 px-4 pb-4">
                          {property.insolvency?.properties?.map((insolvencyProperty, index) => (
                            <SlidePropertyCard key={`property_${index}`} property={insolvencyProperty} />
                          ))}
                        </div>
                      </div>

                    </div>

                  </dl>

                </div>

              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
