import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { nullFn } from "~/src/lib/standard";

import { priceLabels } from "~/src/modules/labels";

/**
 *
 * @param props - The root object
 * @param props.value - The root object
 * @param props.onChange - The root object
 * @param props.className - The root object
 * @param props.buttonClassName - The root object
 * @param props.options - The root object
 * @param props.enabledOptions - The root object
 * @example
 */
const PriceSelect = ({
  buttonClassName = "",
  className = "",
  enabledOptions = null,
  onChange = nullFn,
  options = [],
  value
}) => {
  const mapLabelsToListBoxOptions = (option) => {
    const optionDisabled = enabledOptions && !enabledOptions.includes(option);
    const listBoxClassName = optionDisabled
      ? "text-gray-700 bg-gray-300 cursor-default select-none relative px-2 py-1"
      : ({ active }) => `${active ? "text-gray-900 bg-gray-100" : "text-gray-700"} cursor-pointer select-none relative px-2 py-1`;

    return (
      <Listbox.Option
        as="li"
        className={listBoxClassName}
        disabled={optionDisabled}
        key={option}
        value={option}
      >

        {({ selected }) => (
          <>
            <span className={`${selected ? "font-medium" : "font-normal"} block truncate`}>
              {priceLabels[option]}
            </span>
          </>
        )}
      </Listbox.Option>
    );
  };

  return (
    <Listbox as="div" className={cn("relative flex flex-row rounded cursor-pointer", className)} onChange={onChange} value={value}>
      <Listbox.Button className="flex w-full items-center justify-between rounded border border-gray-300 bg-white px-2 py-1">
        <span className="text-sm text-gray-900">{priceLabels[value]}</span>

        <ChevronDownIcon className="size-5 text-gray-400" />
      </Listbox.Button>

      <Listbox.Options
        as="ul"
        className="absolute z-10 mt-8 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        modal={false}
      >
        {options.map((option) => (mapLabelsToListBoxOptions(option)
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export default PriceSelect;
