import {
  Dialog,
  DialogBackdrop,
  DialogPanel
} from "@headlessui/react";
import {
  useEffect,
  useRef
} from "react";

import useAccountRegions from "~/src/hooks/use-account-regions.js";

import CloseIcon from "~/src/ui/icons/close-icon/index.jsx";

import RegionFilterState from "./region-filter-state.jsx";

const sortStates = (unsortedStates) => [...unsortedStates].sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB)).reverse();

/**
 *
 * @param props0 - The root object
 * @param props0.active - The root object
 * @param props0.cancelRegionFilter - The root object
 * @param props0.hide
 * @param props0.queryKey
 * @param props0.queryRegion
 * @param props0.submit
 * @param props0.tempQuery
 * @param props0.update
 * @example
 */
export default function RegionFilterModal({
  active, cancelRegionFilter, hide, queryKey = "region", queryRegion, submit, tempQuery, update
}) {
  const { account_regions: accountRegions = {} } = useAccountRegions();

  const queriedRegions = tempQuery?.[queryKey] || queryRegion;

  const cancelButtonReference = useRef();

  const accountRegionsArray = Object.values(accountRegions);

  const initialStates = accountRegionsArray
    .map(({
      regions,
      stateData
    }) => {
      const updatedRegions = regions.map(({
        id, code, ...region
      }) => ({
        id,
        ...region,
        checked: queriedRegions.includes(Number(id))
      }));

      const checked = updatedRegions.every(({ checked: regionChecked }) => regionChecked);
      const indeterminate = updatedRegions.some(({ checked: regionChecked }) => regionChecked) && !checked;

      return {
        ...stateData,
        checked,
        indeterminate,
        regions: updatedRegions
      };
    });

  const sortedStates = sortStates(initialStates);

  const updateStateRegions = (id, regions) => {
    const regionsChecked = regions.filter(({ checked }) => checked === true);
    const regionsUnchecked = regions.filter(({ checked }) => checked === false);

    const regionsCheckedFromState = regionsChecked.map((region) => Number(region.id));
    const regionsUncheckedFromState = new Set(regionsUnchecked.map((region) => Number(region.id)));

    const filteredRegions = queriedRegions.filter(
      (regionId) => !regionsUncheckedFromState.has(Number(regionId))
    );

    const updatedRegions = [...new Set([...filteredRegions, ...regionsCheckedFromState])];

    update(
      queryKey,
      updatedRegions
    );
  };

  useEffect(() => {
    update(
      queryKey,
      queryRegion
    );
  }, []);

  const handleSubmit = function () {
    submit();
    hide();
  };

  const handleCancel = () => {
    cancelRegionFilter();
    hide();
  };

  return (
    <Dialog
      className="relative z-[150]"
      initialFocus={cancelButtonReference}
      onClose={handleCancel}
      open={active}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex size-full max-h-full flex-col bg-white sm:max-h-full sm:max-w-3xl sm:rounded-lg"
        >
          <div className="relative border-b border-gray-200 p-4 sm:px-6">
            <p className="text-center text-lg font-medium">Regionen filtern</p>

            <div className="absolute right-4 top-0 hidden h-full items-center sm:flex">
              <button
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                onClick={handleCancel}
                type="button"
              >
                <CloseIcon className="size-5 text-black" />
              </button>
            </div>
          </div>

          <div className="min-h-0 grow divide-y divide-gray-300 overflow-y-scroll bg-white px-4 sm:divide-gray-200 sm:px-12">

            {
              sortedStates.map(({
                id,
                checked,
                indeterminate,
                name,
                regions
              }) => (
                <RegionFilterState
                  key={id}
                  {...{
                    id,
                    checked,
                    indeterminate,
                    name,
                    regions,
                    updateStateRegions
                  }}
                />
              ))
            }

          </div>

          <div className="space-y-4 border-t border-gray-200 p-4 text-center sm:flex sm:flex-row-reverse sm:items-center sm:justify-between sm:px-6">
            <button
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none sm:ml-3 sm:w-auto"
              onClick={handleSubmit}
              type="button"
            >
              Suchen
            </button>

            <button
              className="font-medium text-gray-700 underline hover:bg-gray-50 focus:outline-none"
              onClick={handleCancel}
              ref={cancelButtonReference}
              type="button"
            >
              zurück
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
