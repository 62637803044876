import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth.js";
import useStore from "~/src/hooks/use-store.js";

/**
 *
 * @example
 */
const SettingsDropdown = () => {
  const { user } = useAuth();

  const toggleLogoutModal = useStore((state) => state.toggleLogoutModal);
  const toggleAreaModal = useStore((state) => state.toggleAreaModal);
  const toggleProjectSuggestionModal = useStore((state) => state.toggleProjectSuggestionModal);

  return (
    <div className="relative z-30 flex items-center">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="inline-flex items-center justify-center rounded-md text-white hover:text-gray-200 focus:text-gray-100 focus:outline-none">
              <div className="group flex flex-col items-center justify-center gap-1">
                <div className="flex size-8 items-center justify-center rounded-full bg-gray-100 text-primary group-hover:bg-primary-light lg:size-5">
                  <span className="pt-0.5 text-lg lg:text-xs">{user.username[0].toUpperCase()}</span>
                </div>

                <span className="hidden text-[0.65rem] leading-none text-white group-hover:text-primary-light lg:flex">Benutzer</span>
              </div>

            </Menu.Button>

            <Transition
              as="div"
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              show={open}
            >
              <Menu.Items static>
                <div className="absolute right-3 mt-4 min-w-56 max-w-56 origin-top-right rounded-md bg-white p-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 lg:text-xs">
                  <Menu.Item>
                    <button className="flex w-full truncate rounded bg-gray-200 px-4 py-2 text-gray-700 focus:outline-none" disabled={true} onClick={() => { }} role="menuitem">
                      <p className="text-left">Eingeloggt als<br /><span className="whitespace-nowrap font-semibold">{`${user.email}`}</span></p>
                    </button>
                  </Menu.Item>

                  <Menu.Item>
                    <Link className="flex w-full rounded px-4 py-2 text-gray-700 hover:bg-primary hover:text-white focus:outline-none" role="menuitem" to="/merklisten">
                      Merklisten
                    </Link>
                  </Menu.Item>

                  <Menu.Item>
                    <button className="flex w-full rounded px-4 py-2 text-gray-700 hover:bg-primary hover:text-white focus:outline-none" onClick={toggleAreaModal} role="menuitem">
                      Flächengewichtung
                    </button>
                  </Menu.Item>

                  <Menu.Item>
                    <button className="flex w-full rounded px-4 py-2 text-gray-700 hover:bg-primary hover:text-white focus:outline-none" onClick={toggleProjectSuggestionModal} role="menuitem">
                      Projekt vorschlagen
                    </button>
                  </Menu.Item>

                  <Menu.Item>
                    <button className="flex w-full rounded px-4 py-2 text-gray-700 hover:bg-primary hover:text-white focus:outline-none" onClick={toggleLogoutModal} role="menuitem">
                      Logout
                    </button>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>

          </>
        )}
      </Menu>
    </div>
  );
};

export default SettingsDropdown;
