import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { useEffect, useState } from "react";

import {
  ZKeyAwareNode,
  createMarkers,
  formatCurrency,
  formatCurrencyPerSquaremeter,
  getAxisLeftLegendOffset,
  getXMax,
  getXMin,
  getYMax,
  getYMin
} from "./helpers";

/**
 *
 * @param props - The root object
 * @param props.data - The root object
 * @param props.timeline - The root object
 * @param props.legend - The root object
 * @example
 */
// eslint-disable-next-line max-lines-per-function
export default function ScatterPlot({
  data,
  legend = {
    x: "Zeitlinie",
    y: "Preis"
  },
  timeline
}) {
  const theme = {
    axis: {
      legend: {
        text: {
          fill: "#333333",
          fontSize: 16
        }
      }
    }
  };

  const [animatedData, setAnimatedData] = useState([]);
  const [activeNode, setActiveNode] = useState(null);

  const mouseEnterNode = (node) => {
    setActiveNode(node.id);
  };

  const mouseLeaveNode = () => {
    setActiveNode(null);
  };

  const getNodeSize = (d) => ((activeNode === d.id)
    ? d.data.size * 1.3
    : d.data.size
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => setAnimatedData(data), 1);

    return () => clearTimeout(timeoutId);
  }, [data]);

  return (
    <ResponsiveScatterPlot
      animate={true}
      blendMode="normal"
      colors={(d) => animatedData.find((item) => item.id === d.serieId).color}
      data={animatedData}
      enableGridX={true}
      enableGridY={true}
      markers={createMarkers(timeline)}
      nodeComponent={ZKeyAwareNode}
      nodeOpacity={0.1}
      nodeSize={(d) => getNodeSize(d)}
      onMouseEnter={(node) => mouseEnterNode(node)}
      onMouseLeave={() => mouseLeaveNode()}
      theme={theme}
      tooltip={(datum) => datum.node.data.tooltip}
      useMesh={false}
      xFormat="time:%Y-%m-%dT%H"
      yFormat=">-.2f"
      axisBottom={{
        format: "%b %y'",
        legend: legend.x,
        legendOffset: 60,
        legendPosition: "middle",
        tickPadding: 20
      }}
      axisLeft={{
        format: (legend.y.includes("m²")) ? formatCurrencyPerSquaremeter : formatCurrency,
        legend: legend.y,
        legendOffset: (animatedData.length > 0)
          ? getAxisLeftLegendOffset(legend.y.includes("m²"), animatedData[0].yMax)
          : 0,
        legendPosition: "middle",
        tickPadding: 5,
        tickValues: (animatedData.length > 0)
          ? undefined// getTickValues(animatedData[0].yMin, animatedData[0].yMax)
          : undefined
      }}
      gridYValues={(animatedData.length > 0)
        ? undefined// getTickValues(animatedData[0].yMin, animatedData[0].yMax)
        : undefined}
      margin={{
        bottom: 70,
        left: 160,
        right: 110,
        top: 40
      }}
      xScale={{
        min: (animatedData.length > 0)
          ? getXMin(animatedData[0].xMin, animatedData[0].xMax, timeline)
          : "auto",
        max: (animatedData.length > 0)
          ? getXMax(animatedData[0].xMin, animatedData[0].xMax, timeline)
          : "auto",
        format: "native",
        type: "time"
      }}
      yScale={{
        min: (animatedData.length > 0)
          ? getYMin(animatedData[0].yMin, animatedData[0].yMax)
          : 0,
        max: (animatedData.length > 0)
          ? getYMax(animatedData[0].yMin, animatedData[0].yMax)
          : "auto",
        type: "linear"
      }}
    />
  );
}
