import cn from "classnames";

/**
 *
 * @param props - The root object
 * @param props.className - The root object
 * @param props.small - The root object
 * @param props.size - The root object
 * @example
 */
const ItemSkeleton = ({ className, size = "normal" }) => (
  size === "small"
    ? (
      <div className={cn("animate-pulse flex w-full flex-col gap-4 border-b border-gray-200 p-3", className)} >
        <div className="flex-col gap-0.5">
          <div className="flex items-start gap-2 pl-1">

            <div className="mt-1.5 size-4 cursor-pointer rounded border border-gray-300 bg-gray-300 text-gray-500 shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-default disabled:opacity-50" />

            <div className="inline-block h-7 w-36 rounded bg-gray-300 text-base font-medium" />

          </div>
        </div>

        <div className="flex pl-6">
          <div className="flex h-[17.5px] w-56 rounded bg-gray-300" />
        </div>

        <div className="flex flex-col gap-0.5 pl-6">
          <div className="flex">
            <div className="w-[200px] shrink-0">
              <div className="flex h-4 w-11/12 rounded bg-gray-300" />
            </div>

            <div className="w-full">
              <div className="flex h-4 w-3/12 rounded bg-gray-300" />
            </div>
          </div>

          <div className="flex">
            <div className="w-[200px] shrink-0">
              <div className="flex h-4 w-9/12 rounded bg-gray-300" />
            </div>

            <div className="w-full">
              <div className="flex h-4 w-7/12 rounded bg-gray-300" />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-0.5 pl-6">
          <div className="flex">
            <div className="w-[200px] shrink-0">
              <div className="flex h-4 w-7/12 rounded bg-gray-300" />
            </div>

            <div className="w-full">
              <div className="flex h-4 w-11/12 rounded bg-gray-300" />
            </div>
          </div>

          <div className="flex">
            <div className="w-[200px] shrink-0">
              <div className="flex h-4 w-10/12 rounded bg-gray-300" />
            </div>

            <div className="w-full">
              <div className="flex h-4 w-11/12 rounded bg-gray-300" />
            </div>
          </div>

          <div className="flex">
            <div className="w-[200px] shrink-0">
              <div className="flex h-4 w-8/12 rounded bg-gray-300" />
            </div>

            <div className="w-full">
              <div className="flex h-4 w-11/12 rounded bg-gray-300" />
            </div>
          </div>

          <div className="flex">
            <div className="w-[200px] shrink-0">
              <div className="flex h-4 w-11/12 rounded bg-gray-300" />
            </div>

            <div className="w-full">
              <div className="flex h-4 w-10/12 rounded bg-gray-300" />
            </div>
          </div>
        </div>
      </div>
    )
    : (
      <div className={cn("animate-pulse mb-12 px-4 py-6 sm:px-6", className)}>
        <div className="grid-cols-9 lg:grid">

          <div className="col-span-6 space-y-4 xl:col-span-3 2xl:col-span-4">
            <div className="h-5 w-1/2 rounded bg-gray-300" />

            <div className="h-5 w-1/3 rounded bg-gray-300" />

            <div className="h-1" />

            <div className="h-5 w-1/2 rounded bg-gray-300" />

            <div className="h-5 w-1/2 rounded bg-gray-300" />

            <div className="h-5 w-1/2 rounded bg-gray-300" />
          </div>

          <div className="col-span-3 mt-12 h-32 w-64 rounded bg-gray-300 lg:ml-8 lg:mt-0 lg:w-48 xl:col-span-2 xl:ml-0 xl:w-64" />

          <div className="col-span-3 mt-12 space-y-4 xl:col-span-4 xl:ml-12 xl:mt-0 2xl:col-span-3 2xl:ml-0">
            <div className="h-5 w-1/2 rounded bg-gray-300" />

            <div className="h-5 rounded bg-gray-300" />

            <div className="h-5 rounded bg-gray-300" />

            <div className="h-1" />

            <div className="h-5 rounded bg-gray-300" />

            <div className="h-5 rounded bg-gray-300" />
          </div>

        </div>
      </div>
    )
);

export default ItemSkeleton;
