import {
  Bars4Icon, BarsArrowDownIcon, BarsArrowUpIcon
} from "@heroicons/react/24/solid";

/**
 *
 * @param root0 - The root object
 * @param root0.isLoading - The root object
 * @param root0.direction - The root object
 * @param root0.onSubmit - The root object
 * @example
 */
export default function PurchasesSortDirection({
  direction = "asc", isLoading, onSubmit
}) {


  /**
   *
   * @example
   */
  function handleClick() {
    onSubmit({ direction: direction === "asc" ? "desc" : "asc" });
  }

  return (
    <button
      className="inline-flex h-8 w-10 items-center justify-center rounded-sm border border-gray-200 bg-white text-base font-medium leading-4 text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-0"
      onClick={handleClick}
      type="button"
    >
      {isLoading &&
        <Bars4Icon className="w-1/2" />}

      {direction === "asc" && !isLoading &&
        <BarsArrowUpIcon className="w-1/2" />}

      {direction === "desc" && !isLoading &&
        <BarsArrowDownIcon className="w-1/2" />}
    </button>
  );
}
