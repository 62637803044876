import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";

/**
 *
 * @param root0 - The root object
 * @param root0.open - The root object
 * @param root0.close - The root object
 * @param root0.resetDropdown - The root object
 * @example
 */
export default function WatchlistDropdownHeader({
  close, open, resetDropdown
}) {
  useEffect(() => {
    if (!open) {
      resetDropdown();
    }
  }, [open, resetDropdown]);

  return (
    <div className="flex items-center justify-between border-b border-gray-200 pb-3">
      <p className="font-light">Speichern in...</p>

      <button className="text-gray-500 hover:text-gray-700 focus:outline-none" onClick={close} type="button">
        <XMarkIcon className="size-5" />
      </button>
    </div>
  );
}
