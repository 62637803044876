import { HomeIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

import useAuth from "~/src/hooks/use-auth";

import BackLink from "~/src/ui/links/back-link";
import Logo from "~/src/ui/logos/logo";

import SubNav from "../subnav";

/**
 *
 * @param props - The root object
 * @param props.fixed - The root object
 * @example
 */
const Nav = ({ fixed = false }) => {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <nav
      id="main-nav-root"
      className={cn("inset-x-0 bg-primary z-[100] shadow sticky", {
        "fixed top-0": fixed
      })}
    >

      <div className="mx-auto px-4">
        <div className="flex h-[48px] justify-between gap-2">
          <div className="flex w-full">
            <div className="flex w-full shrink-0 items-center justify-start gap-4">

              <Link to="/">
                <Logo minWidth="hidden sm:block min-w-[14rem] max-w-[14rem]" />

                <HomeIcon className="block size-5 text-white sm:hidden" />
              </Link>

              {location.pathname.split("/")[1].includes("projekte")
                ? (
                  <div className="flex size-full max-h-[36px] justify-between pl-4">
                    <BackLink alternate="/projekte" label="Projektliste" />

                    <SubNav onlyMenu pageKey={`/${location.pathname.split("/")[1]}`} />
                  </div>
                )
                : (
                  <div className="flex size-full max-h-[36px] justify-start gap-4">
                    <SubNav onlyMenu={false} pageKey={`/${location.pathname.split("/")[1]}`} searchKey={(location.search.includes("slugs") ? "" : location.search)} />
                  </div>
                )}

            </div>
          </div>

          <div className="absolute -bottom-2 left-4 z-20 flex items-center gap-3">
            {
              [
                "dev",
                "staging",
                "test"
              ].includes(globalThis.settings.REACT_APP_HOST_ENV) && (
                <div>
                  <p className="y-1 rounded bg-blue-500 px-2 text-xs font-medium uppercase text-white">{globalThis.settings.REACT_APP_HOST_ENV}</p>
                </div>
              )
            }
          </div>

        </div>
      </div>
    </nav>
  );
};

export default Nav;
