/* eslint-disable max-lines-per-function */

import {
  Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild
} from "@headlessui/react";
import {
  Fragment,
  useEffect, useRef,
  useState
} from "react";

import useEsgCertificates from "~/src/hooks/use-esg-certificates";

import CloseIcon from "~/src/ui/icons/close-icon";

import { adjustCertificatesByQuery, expandCertificates } from "./_common/_exports";
import CertificateFilterState from "./certificate-filter-state";

/**
 *
 * @param root0 - The root object
 * @param root0.active - The root object
 * @param root0.hide - The root object
 * @param root0.queryEsg - The root object
 * @param root0.update - The root object
 * @param root0.submit - The root object
 * @param root0.cancelEsgFilter - The root object
 * @param root0.tempQuery - The root object
 * @param root0.queryKey - The root object
 * @example
 */
export default function EsgCertificatesFilterModal({
  active, cancelEsgFilter, hide, queryEsg, queryKey = "certificates", submit, tempQuery, update
}) {
  const { certificates, mutate } = useEsgCertificates();

  const cancelButtonReference = useRef();

  const [exisitingCertificates, setExisitingCertificates] = useState([]);

  const [updated, setUpdated] = useState(false);

  const updateIssuer = (id, checked) => {
    const issuerAndCertificateIndex = exisitingCertificates.map(({ id: issuerAndCertificateId }) => issuerAndCertificateId).indexOf(id);

    const newIssuerAndCertificate = {
      ...exisitingCertificates[issuerAndCertificateIndex],
      checked
    };

    const newIssuerAndCertificates = exisitingCertificates;

    newIssuerAndCertificates[issuerAndCertificateIndex] = newIssuerAndCertificate;

    setExisitingCertificates(newIssuerAndCertificates);
    setUpdated(true);
  };

  const updateCertificates = (id, certificates) => {
    const issuerAndCertificateIndex = exisitingCertificates.map(({ id: issuerAndCertificateId }) => issuerAndCertificateId).indexOf(id);

    const newCertificates = [...certificates];

    const checked = newCertificates.every(({ checked: certificateChecked }) => certificateChecked);
    const indeterminate = newCertificates.some(({ checked: certificateChecked }) => certificateChecked) && !checked;

    const newIssuerAndCertificate = {
      ...exisitingCertificates[issuerAndCertificateIndex],
      checked,
      indeterminate,
      issuerCertificates: newCertificates
    };

    const newIssuerAndCertificates = exisitingCertificates;

    newIssuerAndCertificates[issuerAndCertificateIndex] = newIssuerAndCertificate;

    setExisitingCertificates(newIssuerAndCertificates);
    setUpdated(true);
  };

  useEffect(() => {
    if (updated) {
      setUpdated(false);

      const filterCertificates = exisitingCertificates
        .map((issuer) => {
          const {
            id: issuerId, issuerCertificates
          } = issuer;

          if (issuerCertificates.length > 0) {
            return issuerCertificates.map((certificate) => {
              const { id: certificateId, checked } = certificate;

              if (checked) {
                return `${issuerId}-${certificateId}`;
              }

              return null;
            })
              .filter((value) => value !== null);
          }

          return null;
        })
        .filter((value) => value?.length)
        .flat();

      const filterIssuers = exisitingCertificates
        .map((issuer) => {
          const {
            id: issuerId, checked: issuerChecked
          } = issuer;

          if (issuerChecked) {
            return `${issuerId}`;
          }

          return null;
        })
        .filter((value) => value?.length)
        .flat();

      update(
        queryKey,
        [...filterIssuers, ...filterCertificates]
      );
    }
  }, [updated]);

  const setInitialCertificates = (query) => {
    if (certificates) {
      const initialCertificates = certificates?.sort((certA, certB) => certA.name.localeCompare(certB.name, "de"))
        .map((certificates) => expandCertificates(certificates))
        .map((certificates) => adjustCertificatesByQuery(certificates, query));

      setExisitingCertificates(initialCertificates);
    }
  };

  useEffect(() => {
    if (certificates && queryEsg) {
      setInitialCertificates(queryEsg);
    }
  }, [certificates]);

  useEffect(() => {
    update(
      queryKey,
      queryEsg
    );
  }, []);

  useEffect(() => {
    setInitialCertificates(queryEsg);
  }, [queryEsg]);

  const handleSubmit = function () {
    submit();
    hide();
  };

  const handleCancel = () => {
    setInitialCertificates(queryEsg);
    cancelEsgFilter();
    hide();
  };

  return (
    <Dialog
      className="relative z-[150]"
      initialFocus={cancelButtonReference}
      onClose={handleCancel}
      open={active}
    >
      <div className="fixed inset-0 flex w-screen items-end justify-center sm:items-center sm:p-4">
        <DialogBackdrop
          transition
          className="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
        />

        <DialogPanel
          transition
          className="flex size-full max-h-full flex-col bg-white sm:max-h-full sm:max-w-3xl sm:rounded-lg"
        >

          <div className="relative border-b border-gray-200 p-4 sm:px-6">
            <p className="text-center text-lg font-medium">Gebäudezertifikat filtern</p>

            <div className="absolute right-4 top-0 hidden h-full items-center sm:flex">
              <button
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                onClick={handleCancel}
                type="button"
              >
                <CloseIcon className="size-5 text-black" />
              </button>
            </div>
          </div>

          <div className="min-h-0 grow divide-y divide-gray-300 overflow-y-scroll bg-white px-4 sm:divide-gray-200 sm:px-12">
            {
              exisitingCertificates.map(({
                id,
                checked,
                indeterminate,
                issuerCertificates,
                name
              }, index) => (
                <CertificateFilterState
                  key={index}
                  {...{
                    id,
                    checked,
                    indeterminate,
                    issuerCertificates,
                    name,
                    updateCertificates,
                    updateIssuer
                  }}
                />
              ))
            }
          </div>

          <div className="space-y-4 border-t border-gray-200 p-4 text-center sm:flex sm:flex-row-reverse sm:items-center sm:justify-between sm:px-6">
            <button
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none sm:ml-3 sm:w-auto"
              onClick={handleSubmit}
              type="button"
            >
              Suchen
            </button>

            <button
              className="font-medium text-gray-700 underline hover:bg-gray-50 focus:outline-none"
              onClick={handleCancel}
              ref={cancelButtonReference}
              type="button"
            >
              zurück
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
