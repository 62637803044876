import {
  Menu, MenuButton, MenuItem, MenuItems
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { sortLabels } from "~/src/modules/labels.js";

/**
 *
 * @param root0 - The root object
 * @param root0.sortBy - The root object
 * @param root0.onSubmit - The root object
 * @example
 */
export default function SortPurchasesDropdown({ onSubmit, sortBy = "dateVerbuecherung" }) {
  return (
    <Menu as="div" className="relative ml-8">
      <MenuButton className="inline-flex h-10 w-full items-center justify-center border-b border-secondary-light text-base font-medium text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-0">
        {sortLabels[sortBy]}

        <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-0.5 size-5 text-gray-500" />
      </MenuButton>

      <MenuItems className="absolute right-0 z-10 -mt-0.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="w-full py-1">
          <MenuItem>
            {({ focus }) => (
              <button
                onClick={() => onSubmit({ sortBy: "dateVerbuecherung" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": focus,
                  "font-bold": sortBy === "dateVerbuecherung",
                  "text-gray-700": !focus
                })}
              >
                {sortLabels.dateVerbuecherung}
              </button>
            )}
          </MenuItem>

          <MenuItem>
            {({ focus }) => (
              <button
                onClick={() => onSubmit({ sortBy: "dateKV" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": focus,
                  "font-bold": sortBy === "dateKV",
                  "text-gray-700": !focus
                })}
              >
                {sortLabels.dateKV}
              </button>
            )}
          </MenuItem>

          <MenuItem>
            {({ focus }) => (
              <button
                onClick={() => onSubmit({ sortBy: "plz" })}
                type="button"
                className={cn("block w-full text-left px-4 py-2 text-sm focus:outline-none", {
                  "bg-gray-100 text-gray-900": focus,
                  "font-bold": sortBy === "plz",
                  "text-gray-700": !focus
                })}
              >
                {sortLabels.plz}
              </button>
            )}
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}
